import React, { useState } from 'react';
import {
  Button,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Tab,
  TextField,
  Typography,
} from '@mui/material';
import styles from '../../styles/UpdateAvailability.module.css';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import { DateTimePicker, DatePicker } from '@mui/x-date-pickers';
import { useSnackbar } from 'notistack';

// Below are the colors of the Slots being visible
const colors = ['Green', 'Blue', 'Purple', 'Yellow'];

const EditDeleteAvailability = ({
  activeEvent,
  availabilityDetailOpen,
  handleAvailabilityDetailClose,
  handleChangeActiveEventSetting,
  MenuProps,
  locations,
  selectedColor,
  handleChangeColor,
  setActiveEvent,
  holdCreators,
  shouldShowEditButton,
  selectedEventType,
  handleChangeEventType,
  updateEvent,
  setAvailabilityDetailOpen,
  deleteEvent,
  frequencies,
  handleChangeActiveEventColor,
  fetchProviderAvailabilityAndSlots,
  selectedProvider,
  handleChangeFrequency,
  handleChangeActiveEventLocation,
}) => {
  const [tabIndex, setTabIndex] = useState('0');
  const { enqueueSnackbar } = useSnackbar();

  return (
    <React.Fragment>
      <Modal
        open={availabilityDetailOpen}
        onClose={handleAvailabilityDetailClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.modalBox}>
          <TabContext value={tabIndex}>
            <TabList onChange={(e, newValue) => setTabIndex(newValue)}>
              {/**
               * Below is Delete Tab
               */}
              <Tab
                label="Delete"
                sx={{
                  backgroundColor: tabIndex === 0 ? 'lightblue' : 'inherit', // Change color when active
                  '&.Mui-selected': { backgroundColor: 'lightblue' }, // Apply color when selected
                }}
                value="0"
              />

              {/**
               * Below is Edit Tab
               */}
              <Tab
                label="Edit"
                sx={{
                  backgroundColor: tabIndex === 1 ? 'lightblue' : 'inherit', // Change color when active
                  '&.Mui-selected': { backgroundColor: 'lightblue' }, // Apply color when selected
                }}
                value="1"
              />
            </TabList>

            {/* Below Tab is for Delete Availability*/}
            <TabPanel value="0">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Event Details
              </Typography>

              <FormControl sx={{ width: 320, margin: 2 }}>
                <DateTimePicker
                  value={activeEvent.start_date_time}
                  disabled
                  label="Start Date"
                />
              </FormControl>

              <FormControl
                sx={{ width: 320, margin: 2 }}
                // error={formValues['insurances'].length>0?false:true}
              >
                <DateTimePicker
                  value={activeEvent.end_date_time}
                  disabled
                  label="End Date"
                />
              </FormControl>

              <FormControl
                sx={{
                  width: 320,
                  margin: 2,
                }}
              >
                <InputLabel id="demo-multiple-checkbox-label">
                  Setting
                </InputLabel>
                <Select
                  disabled
                  labelId="demo-multiple-checkbox-label"
                  name="location"
                  value={activeEvent.setting}
                  onChange={handleChangeActiveEventSetting}
                  input={<OutlinedInput label="apptTypes" />}
                  // renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={null}>
                    <em>None</em>
                  </MenuItem>
                  {['Remote Only', 'In-Person Only', 'Any Setting'].map(
                    (s, index) => (
                      <MenuItem value={s} key={index}>
                        {s}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>

              <FormControl sx={{ width: 320, margin: 2 }}>
                <InputLabel id="demo-multiple-checkbox-label">Color</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  name="color"
                  value={[activeEvent?.color]}
                  onChange={handleChangeActiveEventColor}
                  input={<OutlinedInput label="apptTypes" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                  disabled
                >
                  {colors.map((masterObj, index) => (
                    <MenuItem key={masterObj + index} value={masterObj}>
                      <Checkbox
                        checked={activeEvent?.color?.indexOf(masterObj) > -1}
                      />
                      <ListItemText primary={masterObj} />
                    </MenuItem>
                  ))}
                </Select>
                {/* {formValues['insurances'].length == 0 && <FormHelperText>Required field</FormHelperText>} */}
              </FormControl>

              <FormControl
                sx={{ width: 320, margin: 2 }}
                // error={formValues['insurances'].length>0?false:true}
              >
                <TextField
                  // required
                  disabled
                  autoComplete="off"
                  fullWidth
                  variant="outlined"
                  id="note"
                  label="Note"
                  value={activeEvent?.note}
                  // onChange={(event) => setNote(event.target.value)}
                />
              </FormControl>

              <FormControl sx={{ width: 320, margin: 2 }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Service
                </InputLabel>
                <Select
                  disabled
                  labelId="demo-multiple-checkbox-label"
                  name="location"
                  value={activeEvent.service}
                  onChange={(e) =>
                    setActiveEvent({
                      ...activeEvent,
                      service: e.target.value,
                    })
                  }
                  input={<OutlinedInput label="apptTypes" />}
                  // renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {['Therapy', 'Testing'].map((s, index) => (
                    <MenuItem value={s} key={index}>
                      {s}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ width: 320, margin: 2 }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Setting
                </InputLabel>
                <Select
                  disabled
                  labelId="demo-multiple-checkbox-label"
                  name="location"
                  value={activeEvent.setting}
                  onChange={handleChangeActiveEventSetting}
                  input={<OutlinedInput label="apptTypes" />}
                  // renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={null}>
                    <em>None</em>
                  </MenuItem>
                  {['Remote Only', 'In-Person Only', 'Any Setting'].map(
                    (s, index) => (
                      <MenuItem value={s} key={index}>
                        {s}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>

              <FormControl sx={{ width: 320, margin: 2 }}>
                <FormLabel id="demo-multiple-checkbox-label">Type</FormLabel>
                <RadioGroup
                  row
                  name="type"
                  value={activeEvent.type}
                  onChange={(e) =>
                    setActiveEvent({ ...activeEvent, type: e.target.value })
                  }
                  // renderValue={(selected) => selected.join(', ')}
                >
                  {['Open', 'Hold'].map((s, index) => (
                    <FormControlLabel
                      disabled
                      value={s}
                      control={<Radio />}
                      label={s}
                      key={index}
                    />
                  ))}
                </RadioGroup>
              </FormControl>

              {/*Here i need to add*/}
              {activeEvent.type === 'Hold' && (
                <div>
                  <FormControl
                    sx={{
                      width: 320,
                      margin: 2,
                    }}
                  >
                    <InputLabel id="demo-multiple-checkbox-label">
                      Person Creating Hold
                    </InputLabel>
                    <Select
                      disabled
                      labelId="demo-multiple-checkbox-label"
                      name="holdCreators"
                      value={activeEvent.person_creating_hold}
                      input={<OutlinedInput label="HoldCreators" />}
                      MenuProps={MenuProps}
                    >
                      {holdCreators.map((obj, index) => (
                        <MenuItem key={index} value={obj.care_coordinator_name}>
                          <Checkbox
                            checked={
                              activeEvent.person_creating_hold?.indexOf(
                                obj.care_coordinator_name
                              ) > -1
                            }
                          />
                          <ListItemText primary={obj.care_coordinator_name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    sx={{
                      width: 320,
                      margin: 2,
                    }}
                  >
                    <TextField
                      required
                      autoComplete="off"
                      placeholder="type N/A if not related to a specific client or type Unknown if the client’s name is not known"
                      fullWidth
                      variant="outlined"
                      id="Initials-of-Client"
                      label="Initials of Client"
                      disabled
                      value={activeEvent.clientInitials}
                      InputProps={{
                        inputProps: {
                          maxLength: 8, // Maximum length of 8 characters
                        },
                      }}
                    />
                  </FormControl>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label style={{ marginBottom: '8px' }}>
                      Hold Creation Date
                    </label>
                    <DatePicker
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      value={
                        activeEvent.holdCreationDate
                          ? new Date(activeEvent.holdCreationDate)
                          : null
                      }
                      name="custom_date_docs_due"
                      InputAdornmentProps={{ position: 'start' }}
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{
                        width: '100%',
                      }}
                    />
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label style={{ marginBottom: '8px' }}>
                      Hold Expiration Date and Time
                    </label>
                    <TextField
                      disabled
                      type="datetime-local"
                      fullWidth
                      variant="outlined"
                      value={
                        activeEvent?.holdExpirationDateTime
                          ? moment
                              .utc(activeEvent?.holdExpirationDateTime)
                              .format('YYYY-MM-DDTHH:mm')
                          : null
                      }
                      componentsProps={{
                        label: {
                          shrink: true,
                        },
                      }}
                      style={{
                        width: '100%',
                        border: '1px solid #ced4da',
                        borderRadius: '4px',
                      }}
                    />
                  </div>
                </div>
              )}

              <FormControl sx={{ width: 320, margin: 2 }}>
                <RadioGroup
                  aria-label="Gender"
                  name="type"
                  value={selectedEventType}
                  onChange={handleChangeEventType}
                >
                  <FormControlLabel
                    value="This Event"
                    control={<Radio />}
                    label="This Event"
                  />
                  <FormControlLabel
                    value="All Events"
                    control={<Radio />}
                    label="All Events"
                  />
                  <FormControlLabel
                    value="Future Events"
                    control={<Radio />}
                    label="Future Events"
                  />
                </RadioGroup>
              </FormControl>

              <Row style={{ margin: 14 }} xs="12">
                <Col xs="6">
                  <Row
                    style={{
                      right: '50px',
                      position: 'absolute',
                      display: 'flex',
                      columnGap: 6,
                    }}
                  >
                    <Col xs="6">
                      <Button
                        variant="contained"
                        onClick={async (e) => {
                          setAvailabilityDetailOpen(false);
                        }}
                      >
                        CANCEL
                      </Button>
                    </Col>

                    <Col xs="6">
                      <Button
                        variant="contained"
                        onClick={async (e) => {
                          if (selectedEventType) {
                            await deleteEvent();
                            setAvailabilityDetailOpen(false);
                            await fetchProviderAvailabilityAndSlots(
                              selectedProvider?.id
                            );
                          } else {
                            enqueueSnackbar('Please select required fields.', {
                              variant: 'error',
                              timeout: 3000,
                            });
                          }
                        }}
                      >
                        Delete
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </TabPanel>

            {/* Below Tab is for Edit Availability*/}
            <TabPanel value="1">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Event Details
              </Typography>

              <FormControl
                sx={{ width: 320, margin: 2 }}
                // error={formValues['insurances'].length>0?false:true}
              >
                <DateTimePicker
                  value={activeEvent.start_date_time}
                  // disablePast
                  disabled
                  onChange={() => {
                    // handleSelectedStartDate
                  }}
                  label="Start Date"
                  minutesStep={5}
                  // showTodayButton
                />
              </FormControl>

              <FormControl
                sx={{ width: 320, margin: 2 }}
                // error={formValues['insurances'].length>0?false:true}
              >
                <DateTimePicker
                  value={activeEvent.end_date_time}
                  disabled
                  // disablePast
                  // onChange={handleSelectedEndDate}
                  label="End Date"
                  // showTodayButton
                />
              </FormControl>

              <FormControl
                sx={{ width: 320, margin: 2 }}
                // error={formValues['insurances'].length>0?false:true}
              >
                <InputLabel id="demo-multiple-checkbox-label">
                  Frequency
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  name="frequency"
                  disabled
                  value={[activeEvent.frequency]}
                  onChange={handleChangeFrequency}
                  input={<OutlinedInput label="frequency" />}
                  renderValue={(selected) =>
                    selected?.map((v) => frequencies[v]).join(', ')
                  }
                  MenuProps={MenuProps}
                >
                  {Object.keys(frequencies).map((key, index) => (
                    <MenuItem key={index} value={key}>
                      <Checkbox
                        checked={activeEvent.frequency?.indexOf(key) > -1}
                      />
                      <ListItemText primary={frequencies[key]} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {activeEvent.setting !== 'Remote Only' && (
                <FormControl sx={{ width: 320, margin: 2 }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Location
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    name="location"
                    // disabled
                    value={[activeEvent?.location]}
                    onChange={handleChangeActiveEventLocation}
                    input={<OutlinedInput label="apptTypes" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {locations.map((masterObj, index) => (
                      <MenuItem key={index} value={masterObj}>
                        <Checkbox
                          checked={
                            activeEvent?.location?.indexOf(masterObj) > -1
                          }
                        />
                        <ListItemText primary={masterObj} />
                      </MenuItem>
                    ))}
                  </Select>
                  {/* {formValues['insurances'].length == 0 && <FormHelperText>Required field</FormHelperText>} */}
                </FormControl>
              )}

              {/**
               * Below is for Color
               */}
              <FormControl sx={{ width: 320, margin: 2 }}>
                <InputLabel id="demo-multiple-checkbox-label">Color</InputLabel>
                <Select
                  disabled
                  labelId="demo-multiple-checkbox-label"
                  name="color"
                  value={[activeEvent?.color]}
                  onChange={handleChangeActiveEventColor}
                  input={<OutlinedInput label="apptTypes" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {colors.map((masterObj, index) => (
                    <MenuItem key={index} value={masterObj}>
                      <Checkbox
                        checked={activeEvent?.color?.indexOf(masterObj) > -1}
                      />
                      <ListItemText primary={masterObj} />
                    </MenuItem>
                  ))}
                </Select>
                {/* {formValues['insurances'].length == 0 && <FormHelperText>Required field</FormHelperText>} */}
              </FormControl>

              <FormControl
                sx={{ width: 320, margin: 2 }}
                // error={formValues['insurances'].length>0?false:true}
              >
                <TextField
                  // required
                  // disabled
                  autoComplete="off"
                  fullWidth
                  variant="outlined"
                  id="note"
                  label="Note"
                  value={activeEvent?.note}
                  onChange={(event) =>
                    setActiveEvent({
                      ...activeEvent,
                      note: event.target.value,
                    })
                  }
                />
              </FormControl>

              <FormControl sx={{ width: 320, margin: 2 }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Service
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  name="location"
                  value={activeEvent.service}
                  onChange={(e) =>
                    setActiveEvent({
                      ...activeEvent,
                      service: e.target.value,
                    })
                  }
                  input={<OutlinedInput label="apptTypes" />}
                  // renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {['Therapy', 'Testing'].map((s, index) => (
                    <MenuItem value={s} key={index}>
                      {s}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ width: 320, margin: 2 }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Setting
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  name="location"
                  value={activeEvent.setting}
                  onChange={handleChangeActiveEventSetting}
                  input={<OutlinedInput label="apptTypes" />}
                  // renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={null}>
                    <em>None</em>
                  </MenuItem>
                  {['Any Setting', 'In-Person Only', 'Remote Only'].map(
                    (s, index) => (
                      <MenuItem value={s} key={index}>
                        {s}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>

              <FormControl sx={{ width: 320, margin: 4 }}>
                <FormLabel id="demo-multiple-checkbox-label">Type</FormLabel>
                <RadioGroup
                  row
                  name="type"
                  value={activeEvent.type}
                  onChange={(e) => {
                    if (e.target.value === 'Open') {
                      setActiveEvent({
                        ...activeEvent,
                        person_creating_hold: null,
                        clientInitials: null,
                        holdCreationDate: null,
                        holdExpirationDateTime: null,
                        type: e.target.value,
                      });
                    } else {
                      setActiveEvent({
                        ...activeEvent,
                        type: e.target.value,
                      });
                    }
                  }}
                >
                  {['Open', 'Hold'].map((s, index) => (
                    <FormControlLabel
                      key={index}
                      value={s}
                      control={<Radio />}
                      label={s}
                    />
                  ))}
                </RadioGroup>
              </FormControl>

              {activeEvent.type === 'Hold' && (
                <div>
                  <FormControl
                    sx={{
                      width: 320,
                      margin: 2,
                      border: activeEvent.person_creating_hold
                        ? '1px solid #ced4da'
                        : '1px solid red',
                      borderRadius: '4px',
                      '&:hover': {
                        border: '1px solid #ced4da',
                      },
                    }}
                  >
                    <InputLabel id="demo-multiple-checkbox-label">
                      Person Creating Hold
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      name="holdCreators"
                      value={activeEvent.person_creating_hold}
                      onChange={(e) =>
                        setActiveEvent({
                          ...activeEvent,
                          person_creating_hold: e.target.value,
                        })
                      }
                      input={<OutlinedInput label="HoldCreators" />}
                      MenuProps={MenuProps}
                    >
                      {holdCreators.map((obj, index) => (
                        <MenuItem key={index} value={obj.care_coordinator_name}>
                          <Checkbox
                            checked={
                              activeEvent.person_creating_hold?.indexOf(
                                obj.care_coordinator_name
                              ) > -1
                            }
                          />
                          <ListItemText primary={obj.care_coordinator_name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    sx={{
                      width: 320,
                      margin: 2,
                      border: activeEvent.clientInitials
                        ? '1px solid #ced4da'
                        : '1px solid red',
                      borderRadius: '4px',
                      '&:hover': {
                        border: '1px solid #ced4da',
                      },
                    }}
                  >
                    <TextField
                      required
                      autoComplete="off"
                      placeholder="type N/A if not related to a specific client or type Unknown if the client’s name is not known"
                      fullWidth
                      variant="outlined"
                      id="Initials-of-Client"
                      label="Initials of Client"
                      onChange={(event) => {
                        setActiveEvent({
                          ...activeEvent,
                          clientInitials: event.target.value,
                        });
                      }}
                      value={activeEvent.clientInitials}
                      InputProps={{
                        inputProps: {
                          maxLength: 8, // Maximum length of 8 characters
                        },
                      }}
                    />
                  </FormControl>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label style={{ marginBottom: '8px' }}>
                      Hold Creation Date
                    </label>
                    <DatePicker
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      value={
                        activeEvent.holdCreationDate
                          ? new Date(activeEvent.holdCreationDate)
                          : null
                      }
                      name="custom_date_docs_due"
                      InputAdornmentProps={{ position: 'start' }}
                      onChange={(date) =>
                        setActiveEvent({
                          ...activeEvent,
                          holdCreationDate: date,
                        })
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{
                        width: '100%', // Make it full width relative to its container
                        border: activeEvent.holdCreationDate
                          ? '1px solid #ced4da'
                          : '1px solid red',
                        borderRadius: '4px',
                      }}
                    />
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormLabel style={{ marginBottom: '8px' }}>
                      Hold Expiration Date and Time
                    </FormLabel>
                    <TextField
                      type="datetime-local"
                      fullWidth
                      variant="outlined"
                      value={
                        activeEvent?.holdExpirationDateTime
                          ? moment
                              .utc(activeEvent?.holdExpirationDateTime)
                              .format('YYYY-MM-DDTHH:mm')
                          : null
                      }
                      onChange={(event) =>
                        setActiveEvent({
                          ...activeEvent,
                          holdExpirationDateTime: moment.utc(
                            event.target.value
                          ),
                        })
                      }
                      componentsProps={{
                        label: {
                          shrink: true,
                        },
                      }}
                      style={{
                        width: '100%',
                        border: '1px solid #ced4da',
                        borderRadius: '4px',
                      }}
                    />
                  </div>
                </div>
              )}

              <FormControl sx={{ width: 320, margin: 2 }}>
                <RadioGroup
                  aria-label="Gender"
                  name="type"
                  value={'All Events'}
                >
                  <FormControlLabel
                    value="All Events"
                    control={<Radio />}
                    label="All Events"
                  />
                </RadioGroup>
              </FormControl>

              <Row style={{ margin: 14 }} xs="12">
                <Col xs="6">
                  <Row
                    style={{
                      right: '50px',
                      position: 'absolute',
                      display: 'flex',
                      columnGap: 6,
                    }}
                  >
                    <Col xs="6">
                      <Button
                        variant="contained"
                        onClick={async (e) => {
                          setAvailabilityDetailOpen(false);
                        }}
                      >
                        CANCEL
                      </Button>
                    </Col>

                    <Col xs="6">
                      {shouldShowEditButton() && (
                        <Button
                          variant="contained"
                          onClick={async (e) => {
                            await updateEvent();
                            setAvailabilityDetailOpen(false);
                            await fetchProviderAvailabilityAndSlots(
                              selectedProvider?.id
                            );
                          }}
                        >
                          UPDATE
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </TabPanel>
          </TabContext>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default EditDeleteAvailability;
