import React, { useState, useEffect, memo, useCallback, useRef } from 'react';
import Handsontable from 'handsontable';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.css';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import {
  Tabs,
  Tab,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  OutlinedInput,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'; // Adapter for Moment.js
import { cptInsuranceService } from '../../services';
import styles from '../../styles/MyHandsOnTableComponent.module.css';
import { clearLoading, setLoading } from '../../actions/loading';
import { useDispatch } from 'react-redux';

// Below Degree Options defined
const degreeOptions = ['Masters', 'Ph.D.'];

// List of numeric fields to sanitize
const numericFields = [
  'min_time',
  'max_time',
  'masters_low_range',
  'masters_high_range',
  'doctoral_low_range',
  'doctoral_high_range',
  'amount_range',
];

function MyHandsontableComponent() {
  const hotTableRef = useRef(null);
  const archivedHotTableRef = useRef(null);
  const [changes, setChanges] = useState([]); // Store changes locally
  const { enqueueSnackbar } = useSnackbar(); // This hook provides the enqueueSnackbar method
  const [activeTab, setActiveTab] = useState(0); // 0 for Current, 1 for Archived
  const [currentData, setCurrentData] = useState([]);
  const [archivedData, setArchivedData] = useState([]);
  const [deletedRowIds, setDeletedRowIds] = useState([]);

  // ----- Filter State Variables -----
  const [selectedCptCodes, setSelectedCptCodes] = useState([]);
  const [selectedInsurance, setSelectedInsurance] = useState('');
  const [selectedDegree, setSelectedDegree] = useState('');
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [dosDate, setDosDate] = useState(null);
  // Above Filter State Variables Ends

  // CPT Code options - dynamically populated
  const [cptCodeOptions, setCptCodeOptions] = useState([]);

  // Insurance and Location options - dynamically populated
  const [insuranceOptions, setInsuranceOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]); // Initially empty, will be populated based on data

  // Filtered data states
  const [filteredCurrentData, setFilteredCurrentData] = useState([]);
  const [filteredArchivedData, setFilteredArchivedData] = useState([]);

  // Column visibility state based on Degree filter
  const [hiddenColumns, setHiddenColumns] = useState([]);

  // ----- State and Functions for Update Entry Dialog -----
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  // Form state
  const [mastersRate, setMastersRate] = useState('');
  const [doctoralRate, setDoctoralRate] = useState('');
  const [effectiveDate, setEffectiveDate] = useState(null);

  // This is the new row's optional expiration date
  const [expirationDate, setExpirationDate] = useState(null);

  // This is the current row's optional expiration date
  const [currentRowExpirationDate, setCurrentRowExpirationDate] = useState('');

  const dispatch = useDispatch();

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  /*
   The below useEffect adds event listeners for
   copy and paste actions.
  */
  useEffect(() => {
    const handleCopy = (event) => {
      console.log('Copy event triggered');
      const clipboardData = event.clipboardData || window.clipboardData;
      console.log('Clipboard items:', clipboardData.items);
      const data = clipboardData.getData('Text');
      console.log('Copying data:', data);
    };

    const handlePaste = (event) => {
      console.log('Paste event triggered');
      console.log('Pasting data:', event.clipboardData.getData('Text'));
    };
    document.addEventListener('copy', handleCopy);
    document.addEventListener('paste', handlePaste);

    return () => {
      document.removeEventListener('copy', handleCopy);
      document.removeEventListener('paste', handlePaste);
    };
  }, []);

  // ----- Define Columns for Handsontable (Excluding 'id') -----
  // 1) Change date columns from 'date' to 'text'
  // 2) Change 'amount_range' from 'checkbox' to 'text'
  // ----- Define Columns for Handsontable (Excluding 'id') -----
  const columns = [
    { data: 'cpt_code', type: 'text' },
    {
      data: 'insurance',
      type: 'autocomplete',
      source: function (query, callback) {
        // Return all possible insurances or filter if you want
        if (!insuranceOptions) {
          callback([]);
        } else {
          callback(insuranceOptions);
        }
      },
      filter: true,
      strict: false,
      allowInvalid: true,
      placeholder: 'Select or type Insurance',
    },
    // 3rd: Location
    { data: 'office_location', type: 'text' },
    // 4th: Master's Amount
    { data: 'masters_amount', type: 'numeric' },
    // 5th: Doctoral Amount
    { data: 'doctoral_amount', type: 'numeric' },
    { data: 'service_group', type: 'text' },
    { data: 'service_type', type: 'text' },
    { data: 'service_type_subtype', type: 'text' },
    { data: 'setting_location_dependent', type: 'text' },
    { data: 'time_bound', type: 'text' },
    { data: 'min_time', type: 'numeric' },
    { data: 'max_time', type: 'numeric' },
    {
      data: 'amount_range',
      type: 'text', // changed from checkbox
    },
    { data: 'masters_low_range', type: 'numeric' },
    { data: 'masters_high_range', type: 'numeric' },
    { data: 'doctoral_low_range', type: 'numeric' },
    { data: 'doctoral_high_range', type: 'numeric' },
    { data: 'notes', type: 'text' },
    { data: 'effective_date', type: 'text' },
    { data: 'end_date', type: 'text' },
    { data: 'updated_date', type: 'text' },
  ];
  // ----- End of Define Columns for Handsontable -----

  useEffect(() => {
    showLoading(); // Show loading
    cptInsuranceService
      .getAllBillingRates()
      .then((response) => {
        /*
         Saving data in currentData those that don't have endDate
         It means that this is current
        */
        const current = response.data
          .filter((rate) => !rate.end_date)
          .map((rate) => ({
            id: rate.id,
            cpt_code: rate.cpt_code || '', // Ensure default values
            service_group: rate.service_group || '',
            service_type: rate.service_type || '',
            service_type_subtype: rate.service_type_subtype || '',
            insurance: rate.insurance || '',
            setting_location_dependent: rate.setting_location_dependent || '',
            office_location: rate.office_location || '',
            masters_amount:
              rate.masters_amount !== null ? rate.masters_amount : null,
            doctoral_amount:
              rate.doctoral_amount !== null ? rate.doctoral_amount : null,
            time_bound: rate.time_bound || '',
            min_time: rate.min_time !== null ? rate.min_time : null,
            max_time: rate.max_time !== null ? rate.max_time : null,
            amount_range:
              typeof rate.amount_range === 'boolean'
                ? rate.amount_range
                : rate.amount_range || '', // Keep it as text, fallback to ''
            masters_low_range:
              rate.masters_low_range !== null ? rate.masters_low_range : null,
            masters_high_range:
              rate.masters_high_range !== null ? rate.masters_high_range : null,
            doctoral_low_range:
              rate.doctoral_low_range !== null ? rate.doctoral_low_range : null,
            doctoral_high_range:
              rate.doctoral_high_range !== null
                ? rate.doctoral_high_range
                : null,
            notes: rate.notes || '',
            effective_date: rate.effective_date || '', // Now stored as text
            end_date: rate.end_date || '', // Now stored as text
            updated_date: rate.updatedAt
              ? moment(rate.updatedAt).format('YYYY-MM-DD')
              : '',
          }));

        /*
         Saving data in archivedData because those have
         end_date
        */
        const archived = response.data
          .filter((rate) => rate.end_date)
          .map((rate) => ({
            id: rate.id,
            cpt_code: rate.cpt_code || '', // Ensure default values
            service_group: rate.service_group || '',
            service_type: rate.service_type || '',
            service_type_subtype: rate.service_type_subtype || '',
            insurance: rate.insurance || '',
            setting_location_dependent: rate.setting_location_dependent || '',
            office_location: rate.office_location || '',
            masters_amount:
              rate.masters_amount !== null ? rate.masters_amount : null,
            doctoral_amount:
              rate.doctoral_amount !== null ? rate.doctoral_amount : null,
            time_bound: rate.time_bound || '',
            min_time: rate.min_time !== null ? rate.min_time : null,
            max_time: rate.max_time !== null ? rate.max_time : null,
            amount_range:
              typeof rate.amount_range === 'boolean'
                ? rate.amount_range
                : rate.amount_range || '', // fallback to ''
            masters_low_range:
              rate.masters_low_range !== null ? rate.masters_low_range : null,
            masters_high_range:
              rate.masters_high_range !== null ? rate.masters_high_range : null,
            doctoral_low_range:
              rate.doctoral_low_range !== null ? rate.doctoral_low_range : null,
            doctoral_high_range:
              rate.doctoral_high_range !== null
                ? rate.doctoral_high_range
                : null,
            notes: rate.notes || '',
            effective_date: rate.effective_date || '', // text
            end_date: rate.end_date || '', // text
            updated_date: rate.updatedAt
              ? moment(rate.updatedAt).format('YYYY-MM-DD')
              : '',
          }));

        // ----- Populate CPT Code Options with Sorting -----
        let uniqueCptCodes = Array.from(
          new Set(response.data.map((rate) => rate.cpt_code))
        ).filter((code) => code); // Remove falsy values

        // Separate numerical and alphabetical CPT codes
        const numericalCptCodes = uniqueCptCodes
          .filter((code) => /^\d+$/.test(code))
          .sort((a, b) => Number(a) - Number(b));

        const alphabeticalCptCodes = uniqueCptCodes
          .filter((code) => /[A-Za-z]/.test(code))
          .sort();

        // Filtering to make cpt code unique
        uniqueCptCodes = [...numericalCptCodes, ...alphabeticalCptCodes];

        // Setting CPT code below
        setCptCodeOptions(uniqueCptCodes);
        // ----- End of Populate CPT Code Options with Sorting -----

        // ----- Populate Insurance Options Dynamically -----
        const uniqueInsurances = Array.from(
          new Set(response.data.map((rate) => rate.insurance))
        ).filter((ins) => ins && ins.trim() !== '');

        setInsuranceOptions(uniqueInsurances);
        // ----- End of Populate Insurance Options Dynamically -----

        setCurrentData(current);
        setArchivedData(archived);

        // Initially, filtered data is the same as fetched data
        setFilteredCurrentData(current);
        setFilteredArchivedData(archived);

        // ----- Populate Location Options Based on Data -----
        const allFilteredData = [...current, ...archived];

        // Filtering uniqueLocation
        const uniqueLocations = Array.from(
          new Set(
            allFilteredData
              .map((rate) => rate.office_location)
              .filter((loc) => loc && loc.trim() !== '')
              .flatMap((loc) => loc.split(',').map((l) => l.trim()))
          )
        ).sort();
        setLocationOptions(uniqueLocations);
        // ----- End of Populate Location Options Based on Data -----

        hideLoading(); // Hide loading
      })
      .catch((error) => {
        console.log('error.message', error.message);
        hideLoading(); // Ensure loading is hidden even on error
        enqueueSnackbar(`Error fetching data: ${error.message}`, {
          variant: 'error',
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ----- Filtering Logic -----
  useEffect(() => {
    const applyFilters = () => {
      // Function to filter data based on selected filters
      const filterData = (data) => {
        return data.filter((row) => {
          const {
            cpt_code,
            insurance,
            office_location,
            effective_date,
            end_date,
          } = row;

          // Filter by CPT Code (multiple selection)
          if (
            selectedCptCodes.length > 0 &&
            !selectedCptCodes.includes(cpt_code)
          ) {
            return false;
          }

          // Filter by Insurance
          if (selectedInsurance && insurance !== selectedInsurance) {
            return false;
          }

          // Filter by Location (multiple selection, handle multiple locations per cell)
          if (selectedLocations.length > 0) {
            const rowLocations = office_location
              ? office_location.split(',').map((loc) => loc.trim())
              : [];
            const hasLocation = selectedLocations.some((loc) =>
              rowLocations.includes(loc)
            );
            if (!hasLocation) {
              return false;
            }
          }

          // Filter by DOS (Date of Service)
          if (dosDate) {
            const dos = moment(dosDate).startOf('day');
            const effDate = moment(effective_date).startOf('day');
            const enDate = end_date ? moment(end_date).startOf('day') : null;

            if (!effDate.isValid()) return false;

            if (dos.isBefore(effDate)) return false;

            if (enDate && dos.isAfter(enDate)) return false;
          }

          return true;
        });
      };

      // Apply filters to current and archived data
      const newFilteredCurrent = filterData(currentData);
      const newFilteredArchived = filterData(archivedData);

      setFilteredCurrentData(newFilteredCurrent);
      setFilteredArchivedData(newFilteredArchived);

      // ----- Update Location Options Based on Filtered Data -----
      const allFilteredData = [...newFilteredCurrent, ...newFilteredArchived];
      const uniqueLocations = Array.from(
        new Set(
          allFilteredData
            .map((rate) => rate.office_location)
            .filter((loc) => loc && loc.trim() !== '')
            .flatMap((loc) => loc.split(',').map((l) => l.trim()))
        )
      ).sort();

      // If uniqueLocations is empty, set locationOptions to empty array
      setLocationOptions(uniqueLocations);
      // ----- End of Update Location Options Based on Filtered Data -----

      // ----- Automatic Tab Switching Based on DOS Filter -----
      if (dosDate) {
        const currentHasData = newFilteredCurrent.length > 0;
        const archivedHasData = newFilteredArchived.length > 0;

        if (currentHasData && !archivedHasData && activeTab !== 0) {
          setActiveTab(0); // Switch to Current tab
        } else if (!currentHasData && archivedHasData && activeTab !== 1) {
          setActiveTab(1); // Switch to Archived tab
        }
        // If both have data or neither has data, retain current tab
      }
      // ----- End of Automatic Tab Switching Based on DOS Filter -----
    };

    applyFilters();
  }, [
    currentData,
    archivedData,
    selectedCptCodes,
    selectedInsurance,
    selectedLocations,
    dosDate,
    activeTab, // Added activeTab to dependencies to ensure it reacts to changes
  ]);
  // ----- End of Filtering Logic -----

  // ----- Manage Column Visibility Based on Degree Filter -----
  useEffect(() => {
    if (selectedDegree === 'Masters') {
      setHiddenColumns(['doctoral_amount']); // Hide Doctoral Amount
    } else if (selectedDegree === 'Ph.D.') {
      setHiddenColumns(['masters_amount']); // Hide Master's Amount
    } else {
      setHiddenColumns([]); // Show both columns
    }
  }, [selectedDegree]);
  // ----- End of Manage Column Visibility -----

  /**
   * The below useEffect is responsible for sorting Table
   */
  useEffect(() => {
    /**
     * And, I think this setTimeOut is there. To sort
     * the table once the component has been rendered
     */
    setTimeout(() => {
      if (hotTableRef.current && hotTableRef.current.hotInstance) {
        hotTableRef.current.hotInstance.getPlugin('multiColumnSorting').sort([
          { column: 0, sortOrder: 'asc' },
          { column: 1, sortOrder: 'asc' },
        ]);
      }
    }, 300);
  }, []); // Now it runs only once on mount

  // useEffect(() => {
  //   if (hotTableRef.current && hotTableRef.current.hotInstance) {
  //     const hotInstance = hotTableRef.current.hotInstance;
  //     hotInstance.addHook('beforeChange', (changes, source) => {
  //       if (source !== 'loadData' && source !== 'undo' && source !== 'redo') {
  //         const sortingPlugin = hotInstance.getPlugin('multiColumnSorting');
  //         if (sortingPlugin && sortingPlugin.isEnabled()) {
  //           sortingPlugin.disablePlugin();
  //           console.log('Multi-column sorting disabled due to user change.');
  //         }
  //       }
  //     });
  //   }
  // }, []); // Now it runs only once on mount

  // Force initial sorting on load for the Archived Tab using multiColumnSorting plugin
  useEffect(() => {
    setTimeout(() => {
      if (
        archivedHotTableRef.current &&
        archivedHotTableRef.current.hotInstance
      ) {
        archivedHotTableRef.current.hotInstance
          .getPlugin('multiColumnSorting')
          .sort([
            { column: 0, sortOrder: 'asc' },
            { column: 1, sortOrder: 'asc' },
          ]);
      }
    }, 300);
  }, []);

  // ----- Handler Functions for Filters -----
  const handleCptCodeChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCptCodes(typeof value === 'string' ? value.split(',') : value);
  };

  // Setting Insurance State
  const handleInsuranceChange = (event) => {
    setSelectedInsurance(event.target.value);
  };

  // Setting Degree State
  const handleDegreeChange = (event) => {
    setSelectedDegree(event.target.value);
  };

  // Setting Location State
  const handleLocationChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedLocations(typeof value === 'string' ? value.split(',') : value);
  };

  const handleDosDateChange = (date) => {
    setDosDate(date);
  };

  // Function to reset all the filters on the page.
  const resetFilters = () => {
    setSelectedCptCodes([]);
    setSelectedInsurance('');
    setSelectedDegree('');
    setSelectedLocations([]);
    setDosDate(null);

    // ----- Automatically Switch to Current Tab After Resetting Filters -----
    setActiveTab(0); // Switch to Current tab (index 0)
    // ----- End of Automatically Switch to Current Tab After Resetting Filters -----
  };
  // ----- End of Handler Functions for Filters -----

  // Utility function to sanitize numeric fields
  const sanitizeNumericFields = (data, fields) => {
    const sanitizedData = { ...data };

    fields.forEach((field) => {
      if (sanitizedData[field] === '') {
        // If the field is an empty string, set it to null
        sanitizedData[field] = null;
      } else if (typeof sanitizedData[field] === 'boolean') {
        // If the field is a boolean, convert it to 1 (true) or 0 (false)
        sanitizedData[field] = sanitizedData[field] ? 1 : 0;
      } else if (
        sanitizedData[field] !== null &&
        typeof sanitizedData[field] !== 'number'
      ) {
        // If the field is neither null nor a number, attempt to parse it as an integer
        const parsedValue = parseInt(sanitizedData[field], 10);
        sanitizedData[field] = isNaN(parsedValue) ? null : parsedValue;
      }
      // If the field is already a number or null, no action is needed
    });

    return sanitizedData;
  };

  const openUpdateDialog = (rowData) => {
    // Set which row is selected
    setSelectedRowData(rowData);

    // Make all fields blank so the user can enter new values
    setMastersRate('');
    setDoctoralRate('');
    setEffectiveDate(null);
    setExpirationDate(null);
    setCurrentRowExpirationDate('');

    // Show the dialog
    setIsUpdateDialogOpen(true);
  };

  const closeUpdateDialog = () => {
    setIsUpdateDialogOpen(false);
    setSelectedRowData(null);

    // Reset form fields
    setMastersRate('');
    setDoctoralRate('');
    setEffectiveDate(null);
    setExpirationDate(null);
    setCurrentRowExpirationDate('');
  };

  const handleUpdateEntry = async () => {
    // Validate required fields (Masters Rate, Doctoral Rate, Effective Date)
    if (!mastersRate || !doctoralRate || !effectiveDate) {
      enqueueSnackbar('Please fill in all required fields.', {
        variant: 'warning',
      });
      return;
    }

    // Optional: Validate numerical inputs
    if (parseFloat(mastersRate) <= 0 || parseFloat(doctoralRate) <= 0) {
      enqueueSnackbar('Rates must be positive numbers.', {
        variant: 'warning',
      });
      return;
    }

    // Prepare data for the new row
    let newRowData = {
      ...selectedRowData, // Copy all existing fields from the selected row
      id: null, // Ensure the new row has no ID (so it is created fresh)
      masters_amount: parseFloat(mastersRate),
      doctoral_amount: parseFloat(doctoralRate),
      effective_date: moment(effectiveDate).format('YYYY-MM-DD'),
      end_date: expirationDate
        ? moment(expirationDate).format('YYYY-MM-DD')
        : null,
      updated_date: moment().format('YYYY-MM-DD'),
      // amount_range is copied from the selected rowData automatically above
    };

    // Sanitize numeric fields in newRowData to ensure they are numbers or null
    newRowData = sanitizeNumericFields(newRowData, numericFields);

    // Log the sanitized data for debugging
    console.log('Sanitized New Row Data:', newRowData);

    try {
      showLoading(); // Show loading indicator

      // 1. Update the current row's expiration date ONLY if the user entered one
      if (
        currentRowExpirationDate &&
        moment(currentRowExpirationDate, 'YYYY-MM-DD', true).isValid()
      ) {
        await cptInsuranceService.updateBillingRate(selectedRowData.id, {
          end_date: moment(currentRowExpirationDate).format('YYYY-MM-DD'),
          updated_date: moment().format('YYYY-MM-DD'),
        });
        console.log('Current row expiration date updated.');
      }

      // 2. Create a new row with updated rates and effective date
      await cptInsuranceService.createBillingRate(newRowData);
      console.log('New updated entry created.');

      // 3. Refresh the data by re-fetching from the backend
      const response = await cptInsuranceService.getAllBillingRates();
      console.log('Response 1 from getAllBillingRates:', response);

      // Re-map current and archived data with default values
      const updatedCurrent = response.data
        .filter((rate) => !rate.end_date)
        .map((rate) => ({
          id: rate.id,
          cpt_code: rate.cpt_code || '',
          service_group: rate.service_group || '',
          service_type: rate.service_type || '',
          service_type_subtype: rate.service_type_subtype || '',
          insurance: rate.insurance || '',
          setting_location_dependent: rate.setting_location_dependent || '',
          office_location: rate.office_location || '',
          masters_amount:
            rate.masters_amount !== null ? rate.masters_amount : null,
          doctoral_amount:
            rate.doctoral_amount !== null ? rate.doctoral_amount : null,
          time_bound: rate.time_bound || '',
          min_time: rate.min_time !== null ? rate.min_time : null,
          max_time: rate.max_time !== null ? rate.max_time : null,
          amount_range:
            typeof rate.amount_range === 'boolean'
              ? rate.amount_range
              : rate.amount_range || '',
          masters_low_range:
            rate.masters_low_range !== null ? rate.masters_low_range : null,
          masters_high_range:
            rate.masters_high_range !== null ? rate.masters_high_range : null,
          doctoral_low_range:
            rate.doctoral_low_range !== null ? rate.doctoral_low_range : null,
          doctoral_high_range:
            rate.doctoral_high_range !== null ? rate.doctoral_high_range : null,
          notes: rate.notes || '',
          effective_date: rate.effective_date || '',
          end_date: rate.end_date || '',
          updated_date: rate.updated_date || '',
        }));

      const updatedArchived = response.data
        .filter((rate) => rate.end_date)
        .map((rate) => ({
          id: rate.id,
          cpt_code: rate.cpt_code || '',
          service_group: rate.service_group || '',
          service_type: rate.service_type || '',
          service_type_subtype: rate.service_type_subtype || '',
          insurance: rate.insurance || '',
          setting_location_dependent: rate.setting_location_dependent || '',
          office_location: rate.office_location || '',
          masters_amount:
            rate.masters_amount !== null ? rate.masters_amount : null,
          doctoral_amount:
            rate.doctoral_amount !== null ? rate.doctoral_amount : null,
          time_bound: rate.time_bound || '',
          min_time: rate.min_time !== null ? rate.min_time : null,
          max_time: rate.max_time !== null ? rate.max_time : null,
          amount_range:
            typeof rate.amount_range === 'boolean'
              ? rate.amount_range
              : rate.amount_range || '',
          masters_low_range:
            rate.masters_low_range !== null ? rate.masters_low_range : null,
          masters_high_range:
            rate.masters_high_range !== null ? rate.masters_high_range : null,
          doctoral_low_range:
            rate.doctoral_low_range !== null ? rate.doctoral_low_range : null,
          doctoral_high_range:
            rate.doctoral_high_range !== null ? rate.doctoral_high_range : null,
          notes: rate.notes || '',
          effective_date: rate.effective_date || '',
          end_date: rate.end_date || '',
          updated_date: rate.updated_date || '',
        }));

      setCurrentData(updatedCurrent);
      setArchivedData(updatedArchived);

      // ----- Update Insurance Options Based on Latest Data -----
      const uniqueInsurances = Array.from(
        new Set(response.data.map((rate) => rate.insurance))
      ).filter((ins) => ins && ins.trim() !== '');
      setInsuranceOptions(uniqueInsurances);
      // ----- End of Update Insurance Options Based on Latest Data -----

      // ----- Update CPT Code Options Based on Latest Data with Sorting -----
      let uniqueCptCodes = Array.from(
        new Set(response.data.map((rate) => rate.cpt_code))
      ).filter((code) => code); // Remove falsy values

      // Separate numerical and alphabetical CPT codes
      const numericalCptCodes = uniqueCptCodes
        .filter((code) => /^\d+$/.test(code))
        .sort((a, b) => Number(a) - Number(b));
      const alphabeticalCptCodes = uniqueCptCodes
        .filter((code) => /[A-Za-z]/.test(code))
        .sort();

      uniqueCptCodes = [...numericalCptCodes, ...alphabeticalCptCodes];
      setCptCodeOptions(uniqueCptCodes);
      // ----- End of Update CPT Code Options Based on Latest Data with Sorting -----

      // ----- Update Location Options Based on Latest Data -----
      const allFilteredData = [...updatedCurrent, ...updatedArchived];
      const uniqueLocations = Array.from(
        new Set(
          allFilteredData
            .map((rate) => rate.office_location)
            .filter((loc) => loc && loc.trim() !== '')
            .flatMap((loc) => loc.split(',').map((l) => l.trim()))
        )
      ).sort();

      setLocationOptions(uniqueLocations);
      // ----- End of Update Location Options Based on Latest Data -----

      // Show success message
      enqueueSnackbar('Updated entry and created new entry successfully.', {
        variant: 'success',
        autoHideDuration: 3000,
      });

      // Close the dialog
      closeUpdateDialog();
    } catch (error) {
      console.error('Error updating entry:', error);
      enqueueSnackbar(`Error updating entry: ${error.message}`, {
        variant: 'error',
      });
    } finally {
      hideLoading();
    }
  };

  const handleSave = async () => {
    try {
      console.log('Entering handleSave...');
      showLoading();

      let successfulUpdates = 0;
      let successfulCreations = 0;
      let skippedEntries = 0;
      let errors = 0;
      let successfulDeletions = 0;
      let newRowId = null;

      // Determine the data array to modify based on the active tab
      const activeData = activeTab === 0 ? currentData : archivedData;
      console.log('All changes in local state:', changes);

      // 1) Identify updated rows from `changes`
      const uniquePhysicalRows = new Set(changes.map((c) => c.physicalRow));
      console.log('uniquePhysicalRows:', Array.from(uniquePhysicalRows));

      // 2) Update existing rows
      for (const physicalRow of uniquePhysicalRows) {
        const row = activeData[physicalRow];
        if (!row) {
          console.warn(`No row found at physicalRow ${physicalRow}, skipping.`);
          continue;
        }

        if (row.id) {
          // For example, only require cpt_code & insurance
          const isValidEntry = row.cpt_code && row.insurance;
          if (!isValidEntry) {
            console.log(
              `Skipping update for row ${physicalRow} — incomplete:`,
              row
            );
            skippedEntries++;
            continue;
          }

          console.log(`Updating row with ID=${row.id}:`, row);

          const updated_date = moment(row.updated_date).isValid()
            ? moment(row.updated_date).format('YYYY-MM-DD')
            : null;

          // Convert text fields
          const settingLocation = row.setting_location_dependent === 'true';
          const amountRange = row.amount_range === 'true';

          const newRateData = {
            cpt_code: row.cpt_code,
            insurance: row.insurance,
            service_group: row.service_group || '',
            service_type: row.service_type || '',
            service_type_subtype: row.service_type_subtype || '',
            setting_location_dependent: settingLocation,
            office_location: row.office_location || '',
            masters_amount: row.masters_amount
              ? parseFloat(row.masters_amount)
              : null,
            doctoral_amount: row.doctoral_amount
              ? parseFloat(row.doctoral_amount)
              : null,
            time_bound: row.time_bound || '',
            min_time: row.min_time ? parseInt(row.min_time, 10) : null,
            max_time: row.max_time ? parseInt(row.max_time, 10) : null,
            amount_range: amountRange,
            masters_low_range: row.masters_low_range
              ? parseFloat(row.masters_low_range)
              : null,
            masters_high_range: row.masters_high_range
              ? parseFloat(row.masters_high_range)
              : null,
            doctoral_low_range: row.doctoral_low_range
              ? parseFloat(row.doctoral_low_range)
              : null,
            doctoral_high_range: row.doctoral_high_range
              ? parseFloat(row.doctoral_high_range)
              : null,
            notes: row.notes || null,
            effective_date: row.effective_date
              ? moment(row.effective_date).format('YYYY-MM-DD')
              : null,
            end_date: row.end_date
              ? moment(row.end_date).format('YYYY-MM-DD')
              : null,
            updated_date,
          };

          try {
            await cptInsuranceService.updateBillingRate(row.id, newRateData);
            console.log('Update success for ID:', row.id);
            successfulUpdates++;
          } catch (err) {
            console.error('Error updating row ID=', row.id, err);
            errors++;
          }
        }
      }

      // 3) Create new rows for any row with no `id`
      for (let i = 0; i < activeData.length; i++) {
        const row = activeData[i];

        if (!row.id) {
          if (!row.cpt_code && !row.insurance) {
            console.log(`Skipping empty row ${i}`, row);
            continue;
          }

          const isValidEntry = row.cpt_code && row.insurance;
          if (!isValidEntry) {
            console.log(`Skipping new row ${i} — incomplete.`, row);
            skippedEntries++;
            continue;
          }

          console.log(`Creating new row at index=${i}`, row);

          const updated_date = moment(row.updated_date).isValid()
            ? moment(row.updated_date).format('YYYY-MM-DD')
            : null;
          const settingLocation = row.setting_location_dependent === 'true';
          const amountRange = row.amount_range === 'true';

          const newRateData = {
            cpt_code: row.cpt_code,
            insurance: row.insurance,
            service_group: row.service_group || '',
            service_type: row.service_type || '',
            service_type_subtype: row.service_type_subtype || '',
            setting_location_dependent: settingLocation,
            office_location: row.office_location || '',
            masters_amount: row.masters_amount
              ? parseFloat(row.masters_amount)
              : null,
            doctoral_amount: row.doctoral_amount
              ? parseFloat(row.doctoral_amount)
              : null,
            time_bound: row.time_bound || '',
            min_time: row.min_time ? parseInt(row.min_time, 10) : null,
            max_time: row.max_time ? parseInt(row.max_time, 10) : null,
            amount_range: amountRange,
            masters_low_range: row.masters_low_range
              ? parseFloat(row.masters_low_range)
              : null,
            masters_high_range: row.masters_high_range
              ? parseFloat(row.masters_high_range)
              : null,
            doctoral_low_range: row.doctoral_low_range
              ? parseFloat(row.doctoral_low_range)
              : null,
            doctoral_high_range: row.doctoral_high_range
              ? parseFloat(row.doctoral_high_range)
              : null,
            notes: row.notes || null,
            effective_date: row.effective_date
              ? moment(row.effective_date).format('YYYY-MM-DD')
              : null,
            end_date: row.end_date
              ? moment(row.end_date).format('YYYY-MM-DD')
              : null,
            updated_date,
          };

          try {
            const createdRow = await cptInsuranceService.createBillingRate(
              newRateData
            );
            console.log('Created new row => ID=', createdRow.id);
            successfulCreations++;
            // Attach new ID and store newRowId for scrolling (only capture the first new row’s id)
            activeData[i].id = createdRow.id;
            if (!newRowId) {
              newRowId = createdRow.id;
            }
          } catch (err) {
            console.error('Error creating new row i=', i, err);
            errors++;
          }
        }
      }

      // If rows were created successfully (i.e., successfulCreations > 0):
      if (successfulCreations > 0) {
        // Show success notification
        enqueueSnackbar('Created new entries successfully!', {
          variant: 'success',
          autoHideDuration: 2000,
        });

        // Re-fetch updated data instead of doing a full page reload
        try {
          console.log('Re-fetching after creation...');
          const freshResponse1 = await cptInsuranceService.getAllBillingRates();
          const allData = freshResponse1.data || [];

          const newCurrent = allData
            .filter((r) => !r.end_date)
            .map((r) => ({
              id: r.id,
              cpt_code: r.cpt_code || '',
              service_group: r.service_group || '',
              service_type: r.service_type || '',
              service_type_subtype: r.service_type_subtype || '',
              insurance: r.insurance || '',
              setting_location_dependent: r.setting_location_dependent || '',
              office_location: r.office_location || '',
              masters_amount:
                r.masters_amount !== null ? r.masters_amount : null,
              doctoral_amount:
                r.doctoral_amount !== null ? r.doctoral_amount : null,
              time_bound: r.time_bound || '',
              min_time: r.min_time !== null ? r.min_time : null,
              max_time: r.max_time !== null ? r.max_time : null,
              amount_range:
                typeof r.amount_range === 'boolean'
                  ? r.amount_range
                  : r.amount_range || '',
              masters_low_range:
                r.masters_low_range !== null ? r.masters_low_range : null,
              masters_high_range:
                r.masters_high_range !== null ? r.masters_high_range : null,
              doctoral_low_range:
                r.doctoral_low_range !== null ? r.doctoral_low_range : null,
              doctoral_high_range:
                r.doctoral_high_range !== null ? r.doctoral_high_range : null,
              notes: r.notes || '',
              effective_date: r.effective_date || '',
              end_date: r.end_date || '',
              updated_date: r.updatedAt
                ? moment(r.updatedAt).format('YYYY-MM-DD')
                : '',
            }));

          const newArchived = allData
            .filter((r) => r.end_date)
            .map((r) => ({
              id: r.id,
              cpt_code: r.cpt_code || '',
              service_group: r.service_group || '',
              service_type: r.service_type || '',
              service_type_subtype: r.service_type_subtype || '',
              insurance: r.insurance || '',
              setting_location_dependent: r.setting_location_dependent || '',
              office_location: r.office_location || '',
              masters_amount:
                r.masters_amount !== null ? r.masters_amount : null,
              doctoral_amount:
                r.doctoral_amount !== null ? r.doctoral_amount : null,
              time_bound: r.time_bound || '',
              min_time: r.min_time !== null ? r.min_time : null,
              max_time: r.max_time !== null ? r.max_time : null,
              amount_range:
                typeof r.amount_range === 'boolean'
                  ? r.amount_range
                  : r.amount_range || '',
              masters_low_range:
                r.masters_low_range !== null ? r.masters_low_range : null,
              masters_high_range:
                r.masters_high_range !== null ? r.masters_high_range : null,
              doctoral_low_range:
                r.doctoral_low_range !== null ? r.doctoral_low_range : null,
              doctoral_high_range:
                r.doctoral_high_range !== null ? r.doctoral_high_range : null,
              notes: r.notes || '',
              effective_date: r.effective_date || '',
              end_date: r.end_date || '',
              updated_date: r.updatedAt
                ? moment(r.updatedAt).format('YYYY-MM-DD')
                : '',
            }));

          setCurrentData(newCurrent);
          setArchivedData(newArchived);
          console.log(
            'Re-fetched & updated local current/archived after creation.'
          );
        } catch (err) {
          console.error('Error re-fetching after creation.', err);
          errors++;
        }

        // Scroll to the new row in the Handsontable instance (if newRowId was captured)
        if (newRowId) {
          setTimeout(() => {
            const hotInstance = hotTableRef.current.hotInstance;
            let newRowVisualIndex = null;
            const totalRows = hotInstance.countRows();
            for (let i = 0; i < totalRows; i++) {
              const rowData = hotInstance.getSourceDataAtRow(i);
              if (rowData && rowData.id === newRowId) {
                newRowVisualIndex = i;
                break;
              }
            }
            if (newRowVisualIndex !== null) {
              hotInstance.scrollViewportTo(newRowVisualIndex);
            }
          }, 100);
        }

        // *** Re-enable multi‑column sorting after saving ***
        if (activeTab === 0) {
          if (hotTableRef.current && hotTableRef.current.hotInstance) {
            const sortingPlugin =
              hotTableRef.current.hotInstance.getPlugin('multiColumnSorting');
            if (
              sortingPlugin &&
              typeof sortingPlugin.enablePlugin === 'function'
            ) {
              sortingPlugin.enablePlugin();
              sortingPlugin.sort([
                { column: 0, sortOrder: 'asc' },
                { column: 1, sortOrder: 'asc' },
              ]);
              console.log(
                'Multi‑column sorting re‑enabled after saving for current tab.'
              );
            }
          }
        } else {
          if (
            archivedHotTableRef.current &&
            archivedHotTableRef.current.hotInstance
          ) {
            const sortingPlugin =
              archivedHotTableRef.current.hotInstance.getPlugin(
                'multiColumnSorting'
              );
            if (
              sortingPlugin &&
              typeof sortingPlugin.enablePlugin === 'function'
            ) {
              sortingPlugin.enablePlugin();
              sortingPlugin.sort([
                { column: 0, sortOrder: 'asc' },
                { column: 1, sortOrder: 'asc' },
              ]);
              console.log(
                'Multi‑column sorting re‑enabled after saving for archived tab.'
              );
            }
          }
        }

        return;
      }
      // 3.5) Immediately re-fetch so newly created rows have correct IDs
      try {
        console.log('Re-fetching after creation...');
        const freshResponse1 = await cptInsuranceService.getAllBillingRates();
        const allData = freshResponse1.data || [];

        const newCurrent = allData
          .filter((r) => !r.end_date)
          .map((r) => ({
            ...r,
            updated_date: r.updatedAt
              ? moment(r.updatedAt).format('YYYY-MM-DD')
              : '',
          }));
        const newArchived = allData
          .filter((r) => r.end_date)
          .map((r) => ({
            ...r,
            updated_date: r.updatedAt
              ? moment(r.updatedAt).format('YYYY-MM-DD')
              : '',
          }));

        setCurrentData(newCurrent);
        setArchivedData(newArchived);
        console.log(
          'Re-fetched & updated local current/archived after creation.'
        );
      } catch (err) {
        console.error('Error re-fetching after creation.', err);
        errors++;
      }

      // 4) Process any deleted rows
      if (deletedRowIds && deletedRowIds.length > 0) {
        for (const rowId of deletedRowIds) {
          try {
            await cptInsuranceService.deleteBillingRate(rowId);
            console.log('Deleted rowID=', rowId);
            successfulDeletions++;
          } catch (err) {
            console.error(`Error deleting rowID ${rowId}`, err);
            errors++;
          }
        }
        setDeletedRowIds([]);
      }

      // 4.5) RE-FETCH AGAIN AFTER DELETION => ensures deleted rows stay gone
      try {
        console.log('Re-fetching after deletions...');
        const freshResponse2 = await cptInsuranceService.getAllBillingRates();
        const allData2 = freshResponse2.data || [];

        const newCurrent2 = allData2
          .filter((r) => !r.end_date)
          .map((r) => ({
            ...r,
            updated_date: r.updatedAt
              ? moment(r.updatedAt).format('YYYY-MM-DD')
              : '',
          }));
        const newArchived2 = allData2
          .filter((r) => r.end_date)
          .map((r) => ({
            ...r,
            updated_date: r.updatedAt
              ? moment(r.updatedAt).format('YYYY-MM-DD')
              : '',
          }));

        setCurrentData(newCurrent2);
        setArchivedData(newArchived2);
        console.log(
          'Re-fetched & updated local current/archived after deletions.'
        );
      } catch (err) {
        console.error('Error re-fetching after deletion.', err);
        errors++;
      }

      // 5) Show the final summary
      if (errors > 0) {
        enqueueSnackbar(`There were errors processing entries.`, {
          variant: 'error',
        });
      } else if (
        successfulUpdates > 0 ||
        successfulCreations > 0 ||
        successfulDeletions > 0
      ) {
        let msg = `Processed ${successfulUpdates} updates, ${successfulCreations} creations`;
        if (successfulDeletions > 0) {
          msg += `, ${successfulDeletions} deletions`;
        }
        msg += ' successfully.';
        if (skippedEntries > 0) {
          msg += ` Skipped ${skippedEntries} incomplete entries.`;
        }
        enqueueSnackbar(msg, { variant: 'success', autoHideDuration: 3000 });
      } else if (skippedEntries > 0) {
        enqueueSnackbar(
          `Skipped ${skippedEntries} incomplete entries. No changes made.`,
          { variant: 'info', autoHideDuration: 3000 }
        );
      } else {
        enqueueSnackbar('No changes were made.', {
          variant: 'info',
          autoHideDuration: 3000,
        });
      }

      console.log('Clearing local changes state...');
      setChanges([]);
    } catch (error) {
      console.log('Error in handleSave:', error.message);
      enqueueSnackbar(`Error saving data: ${error.message}`, {
        variant: 'error',
      });
    } finally {
      hideLoading();
    }
  };

  // Define Context Menu Configuration
  const contextMenuConfig = {
    items: {
      row_above: {},
      row_below: {},
      col_left: {},
      col_right: {},
      remove_row: {},
      remove_col: {},
      undo: {},
      redo: {},
      make_read_only: {},
      alignment: {},
      '---------': Handsontable.plugins.ContextMenu.SEPARATOR,
      copy: {},
      cut: {},
      paste: {
        name: 'Paste',
        disabled: function () {
          return false;
        },
      },
      // ----- Custom Context Menu Item -----
      create_updated_entry: {
        name: 'Create Updated Entry',
        callback: function (key, selection, clickEvent) {
          // Use `this` to access the Handsontable instance
          const selectedRanges = this.getSelected(); // Returns an array of selected ranges

          if (!selectedRanges || selectedRanges.length === 0) {
            enqueueSnackbar('No row selected.', { variant: 'error' });
            return;
          }

          // Assuming single selection for simplicity
          const [startRow] = selectedRanges[0];
          const selectedRow = startRow;

          const physicalRow = this.toPhysicalRow(selectedRow); // Convert visual row to physical row

          const rowData =
            activeTab === 0
              ? currentData[physicalRow]
              : archivedData[physicalRow];

          if (rowData) {
            // Open the dialog and pass the rowData
            openUpdateDialog(rowData);
          } else {
            enqueueSnackbar('Row data is unavailable.', { variant: 'error' });
          }
        },
      },
      // ----- End of Custom Context Menu Item -----
    },
  };

  function handleAfterChangeCurrent(changes, source) {
    console.log('Current Tab Changes:', changes, 'Source:', source);

    // Log row count for debugging
    if (hotTableRef.current) {
      const hotInstance = hotTableRef.current.hotInstance;
      const rowCount = hotInstance.countRows();
      console.log(
        'Current Tab total rowCount including spare rows =',
        rowCount
      );
    }

    // Skip processing if no relevant changes
    if (
      source === 'loadData' ||
      !Array.isArray(changes) ||
      changes.length === 0
    ) {
      return;
    }

    // Use the CURRENT table ref
    const hotInstance = hotTableRef.current?.hotInstance;

    // Convert all visualRow indices to physicalRow indices
    const physicalChanges = changes.map(
      ([visualRow, prop, oldValue, newValue]) => {
        const physicalRow = hotInstance?.toPhysicalRow(visualRow);
        return { physicalRow, prop, oldValue, newValue };
      }
    );

    // Filter out changes on spare rows
    const numRows = hotInstance?.countRows();
    const minSpareRows = hotInstance?.getSettings().minSpareRows || 0;
    const rowLimitForRealData = numRows - minSpareRows;

    const realRowChanges = physicalChanges.filter(
      ({ physicalRow }) => physicalRow < rowLimitForRealData
    );

    if (realRowChanges.length === 0) {
      console.log(
        'All changes are in the spare rows => skipping handleAfterChangeCurrent'
      );
      return;
    }

    console.log('Real row changes (Current Tab):', realRowChanges);

    // Save these changes into state (if you wish to track them for saving)
    setChanges((currentChanges) => [...currentChanges, ...realRowChanges]);

    // Now we use currentData for the row updates
    realRowChanges.forEach(({ physicalRow, prop, oldValue, newValue }) => {
      const rowData = currentData[physicalRow];

      // Example: If the user changed 'doctoral_amount' for a Medicare row
      if (prop === 'doctoral_amount' && rowData) {
        if (rowData.insurance && rowData.cpt_code && rowData.office_location) {
          const { insurance, office_location, cpt_code } = rowData;
          const doctoralAmount = parseFloat(newValue);

          if (insurance === 'Medicare' && !isNaN(doctoralAmount)) {
            const today = moment().format('YYYY-MM-DD');

            let jhEhpRowIndex = null;
            if (office_location.includes('Silver Spring')) {
              // Handle multiple locations
              for (let i = 0; i < currentData.length; i++) {
                if (
                  currentData[i].insurance === 'Johns Hopkins EHP' &&
                  currentData[i].cpt_code === cpt_code &&
                  currentData[i].office_location
                    .split(',')
                    .map((loc) => loc.trim())
                    .includes('Silver Spring')
                ) {
                  jhEhpRowIndex = i;
                  break;
                }
              }
            }

            if (jhEhpRowIndex !== null) {
              const proceed = window.confirm(
                `Medicare (CPT: ${cpt_code}, Location: Silver Spring) 
  Doctoral changed to ${doctoralAmount}.\n\nIf you proceed:\n
  - Medicare Master's Amount = 75% of ${doctoralAmount}
  - Johns Hopkins EHP Doctoral Amount = 110% of ${doctoralAmount}
  - Johns Hopkins EHP Master's Amount = 95% of ${doctoralAmount}
  Updated Date for both rows will be set to ${today}.\n
  Do you want to apply these updates?`
              );

              if (proceed) {
                // Update Master's Amount for Medicare
                currentData[physicalRow].masters_amount = (
                  doctoralAmount * 0.75
                ).toFixed(2);
                currentData[physicalRow].updated_date = today;

                // Update Johns Hopkins EHP row
                currentData[jhEhpRowIndex].doctoral_amount = (
                  doctoralAmount * 1.1
                ).toFixed(2);
                currentData[jhEhpRowIndex].masters_amount = (
                  doctoralAmount * 0.95
                ).toFixed(2);
                currentData[jhEhpRowIndex].updated_date = today;

                setCurrentData([...currentData]);
              }
            } else {
              const proceed = window.confirm(
                `Insurance: Medicare\nDoctoral Amount changed to ${doctoralAmount}\n\n
  Would you like to update the Master's Amount to 75% of the Doctoral Amount = ${(
    doctoralAmount * 0.75
  ).toFixed(2)}?`
              );

              if (proceed) {
                currentData[physicalRow].masters_amount = (
                  doctoralAmount * 0.75
                ).toFixed(2);
                currentData[physicalRow].updated_date = today;

                setCurrentData([...currentData]);
              }
            }
          }
        } else {
          console.warn(
            `Row data at physicalRow ${physicalRow} is incomplete or undefined. 
  Skipping Medicare logic for Current Tab.`
          );
        }
      }
    });
  }

  function handleAfterChangeArchived(changes, source) {
    // Log row count for debugging
    if (archivedHotTableRef.current) {
      const hotInstance = archivedHotTableRef.current.hotInstance;
      const rowCount = hotInstance.countRows();
      console.log(
        'Archived Tab total rowCount including spare rows =',
        rowCount
      );
    }

    // Skip processing if no relevant changes
    if (
      source === 'loadData' ||
      !Array.isArray(changes) ||
      changes.length === 0
    ) {
      return;
    }

    // Use the ARCHIVED table ref
    const hotInstance = archivedHotTableRef.current?.hotInstance;

    // Convert all visualRow indices to physicalRow indices
    const physicalChanges = changes.map(
      ([visualRow, prop, oldValue, newValue]) => {
        const physicalRow = hotInstance?.toPhysicalRow(visualRow);
        return { physicalRow, prop, oldValue, newValue };
      }
    );

    // Filter out changes on spare rows
    const numRows = hotInstance?.countRows();
    const minSpareRows = hotInstance?.getSettings().minSpareRows || 0;
    const rowLimitForRealData = numRows - minSpareRows;

    const realRowChanges = physicalChanges.filter(
      ({ physicalRow }) => physicalRow < rowLimitForRealData
    );

    if (realRowChanges.length === 0) {
      console.log(
        'All changes are in the spare rows => skipping handleAfterChangeArchived'
      );
      return;
    }

    console.log('Real row changes (Archived Tab):', realRowChanges);

    // Save these changes into state (if you wish to track them for saving)
    setChanges((currentChanges) => [...currentChanges, ...realRowChanges]);

    // Now we use archivedData for the row updates
    realRowChanges.forEach(({ physicalRow, prop, oldValue, newValue }) => {
      const rowData = archivedData[physicalRow];

      if (prop === 'doctoral_amount' && rowData) {
        if (rowData.insurance && rowData.cpt_code && rowData.office_location) {
          const { insurance, office_location, cpt_code } = rowData;
          const doctoralAmount = parseFloat(newValue);

          if (insurance === 'Medicare' && !isNaN(doctoralAmount)) {
            const today = moment().format('YYYY-MM-DD');

            let jhEhpRowIndex = null;
            if (office_location.includes('Silver Spring')) {
              // Handle multiple locations
              for (let i = 0; i < archivedData.length; i++) {
                if (
                  archivedData[i].insurance === 'Johns Hopkins EHP' &&
                  archivedData[i].cpt_code === cpt_code &&
                  archivedData[i].office_location
                    .split(',')
                    .map((loc) => loc.trim())
                    .includes('Silver Spring')
                ) {
                  jhEhpRowIndex = i;
                  break;
                }
              }
            }

            if (jhEhpRowIndex !== null) {
              const proceed = window.confirm(
                `Medicare (CPT: ${cpt_code}, Location: Silver Spring) 
  Doctoral changed to ${doctoralAmount}.\n\nIf you proceed:\n
  - Medicare Master's Amount = 75% of ${doctoralAmount}
  - Johns Hopkins EHP Doctoral Amount = 110% of ${doctoralAmount}
  - Johns Hopkins EHP Master's Amount = 95% of ${doctoralAmount}
  Updated Date for both rows will be set to ${today}.\n
  Do you want to apply these updates?`
              );

              if (proceed) {
                // Update Master's Amount for Medicare
                archivedData[physicalRow].masters_amount = (
                  doctoralAmount * 0.75
                ).toFixed(2);
                archivedData[physicalRow].updated_date = today;

                // Update Johns Hopkins EHP row
                archivedData[jhEhpRowIndex].doctoral_amount = (
                  doctoralAmount * 1.1
                ).toFixed(2);
                archivedData[jhEhpRowIndex].masters_amount = (
                  doctoralAmount * 0.95
                ).toFixed(2);
                archivedData[jhEhpRowIndex].updated_date = today;

                setArchivedData([...archivedData]);
              }
            } else {
              const proceed = window.confirm(
                `Insurance: Medicare\nDoctoral Amount changed to ${doctoralAmount}\n\n
  Would you like to update the Master's Amount to 75% of the Doctoral Amount = ${(
    doctoralAmount * 0.75
  ).toFixed(2)}?`
              );

              if (proceed) {
                archivedData[physicalRow].masters_amount = (
                  doctoralAmount * 0.75
                ).toFixed(2);
                archivedData[physicalRow].updated_date = today;

                setArchivedData([...archivedData]);
              }
            }
          }
        } else {
          console.warn(
            `Row data at physicalRow ${physicalRow} is incomplete or undefined. 
  Skipping Medicare logic for Archived Tab.`
          );
        }
      }
    });
  }

  // ----- End of Updated handleAfterChange -----

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div style={{ width: '100%' }}>
        {/* ----- Filter Container ----- */}
        <div
          className={styles.filterContainer}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '20px',
            marginBottom: '20px',
          }}
        >
          {/* Filter by CPT Code */}
          <FormControl style={{ minWidth: 200 }}>
            <InputLabel id="cptCodeFilterLabel">Filter by CPT Code</InputLabel>
            <Select
              labelId="cptCodeFilterLabel"
              id="cptCodeFilter"
              multiple
              value={selectedCptCodes}
              onChange={handleCptCodeChange}
              input={<OutlinedInput label="Filter by CPT Code" />}
              renderValue={(selected) => selected.join(', ')}
            >
              {cptCodeOptions.map((code) => (
                <MenuItem key={code} value={code}>
                  <Checkbox checked={selectedCptCodes.indexOf(code) > -1} />
                  <ListItemText primary={code} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Filter by Insurance */}
          <FormControl style={{ minWidth: 200 }}>
            <InputLabel id="insuranceFilterLabel">
              Filter by Insurance
            </InputLabel>
            <Select
              labelId="insuranceFilterLabel"
              id="insuranceFilter"
              value={selectedInsurance}
              onChange={handleInsuranceChange}
              label="Filter by Insurance"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {insuranceOptions.map((insurance) => (
                <MenuItem key={insurance} value={insurance}>
                  {insurance}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Filter by Degree */}
          <FormControl style={{ minWidth: 200 }}>
            <InputLabel id="degreeFilterLabel">Filter by Degree</InputLabel>
            <Select
              labelId="degreeFilterLabel"
              id="degreeFilter"
              value={selectedDegree}
              onChange={handleDegreeChange}
              label="Filter by Degree"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {degreeOptions.map((degree) => (
                <MenuItem key={degree} value={degree}>
                  {degree}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Filter by Location */}
          <FormControl style={{ minWidth: 200 }}>
            <InputLabel id="locationFilterLabel">Filter by Location</InputLabel>
            <Select
              labelId="locationFilterLabel"
              id="locationFilter"
              multiple
              value={selectedLocations}
              onChange={handleLocationChange}
              input={<OutlinedInput label="Filter by Location" />}
              renderValue={(selected) => selected.join(', ')}
              disabled={locationOptions.length === 0} // Disable if no options
            >
              {locationOptions.map((location) => (
                <MenuItem key={location} value={location}>
                  <Checkbox
                    checked={selectedLocations.indexOf(location) > -1}
                  />
                  <ListItemText primary={location} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* ----- Added Filter by DOS (Date of Service) ----- */}
          <FormControl style={{ minWidth: 200 }}>
            <DatePicker
              label="Filter by DOS"
              value={dosDate}
              onChange={handleDosDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormControl>
          {/* ----- End of Filter by DOS ----- */}

          {/* Reset Filters Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={resetFilters}
            style={{ height: '56px', alignSelf: 'center' }}
          >
            Reset Filters
          </Button>
        </div>
        {/* ----- End of Filter Container ----- */}

        {/* Existing Tabs */}
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="billing rate tabs"
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          style={{
            paddingTop: '10px',
            paddingBottom: '10px',
            borderBottom: '2px solid #E0E0E0',
            background: '#F5F5F5',
            marginBottom: '20px',
          }}
        >
          <Tab
            label="Current"
            sx={{
              color: 'black',
              '&.Mui-selected': {
                color: 'black', // Ensures the selected tab is also black
              },
            }}
          />
          <Tab
            label="Archived"
            sx={{
              color: 'black',
              '&.Mui-selected': {
                color: 'black', // Ensures the selected tab is also black
              },
            }}
          />
        </Tabs>

        {/* ----- HotTable for Current Tab ----- */}
        {activeTab === 0 && (
          <HotTable
            ref={hotTableRef}
            data={filteredCurrentData}
            licenseKey="non-commercial-and-evaluation" // for non-commercial use only
            colHeaders={[
              'CPT Code',
              'Insurance',
              'Location', // 3rd
              'Master’s Amount', // 4th
              'Doctoral Amount', // 5th
              'Service Group',
              'Service Type',
              'Service Type Subtype',
              'Setting/Location Dependent',
              'Time Bound',
              'Min Time',
              'Max Time',
              'Amount Range',
              'Master’s Low Range',
              'Master’s High Range',
              'Doctoral Low Range',
              'Doctoral High Range',
              'Notes',
              'Effective Date',
              'End Date',
              'Updated Date',
            ]}
            columns={columns} // Use object-based columns
            fixedColumnsStart={2}
            hiddenColumns={{
              columns: hiddenColumns.map((col) =>
                columns.findIndex((c) => c.data === col)
              ),
              indicators: false,
            }}
            rowHeaders={true}
            rowHeights={75}
            width="1300"
            height="600"
            afterChange={handleAfterChangeCurrent}
            minSpareRows={2}
            wordWrap={true}
            filters={false} // Disable built-in filters as we're handling them manually
            dropdownMenu={true}
            multiColumnSorting={true}
            manualRowResize={true}
            manualColumnResize={true}
            contextMenu={contextMenuConfig} // Use the defined context menu config
            copyPaste={true} // Keep only one copyPaste prop
            colWidths={180}
            cells={(row, col, prop) => {
              const cellProperties = {};
              cellProperties.renderer = (instance, td, ...rest) => {
                Handsontable.renderers.TextRenderer.apply(this, [
                  instance,
                  td,
                  ...rest,
                ]);
                td.style.fontSize = '15px';
                td.style.textAlign = 'center';
                td.style.verticalAlign = 'middle';
                td.style.whiteSpace = 'normal';
                td.style.wordWrap = 'break-word';
                td.style.padding = '7px';
              };
              return cellProperties;
            }}
            beforeRemoveRow={(index, amount) => {
              console.log(
                'Before remove row at index:',
                index,
                'Amount:',
                amount
              );

              // Determine the row to remove based on the active tab
              let removedRow;
              if (activeTab === 0) {
                removedRow = filteredCurrentData[index];
              } else {
                removedRow = filteredArchivedData[index];
              }

              console.log('Row to be removed:', removedRow);

              // Track the row ID for deletion if it exists
              const removedId = removedRow && removedRow.id;
              if (removedId) {
                setDeletedRowIds((prev) => [...prev, removedId]);
              }

              // Remove the row from the corresponding data array
              if (removedRow && removedRow.id) {
                if (activeTab === 0) {
                  setCurrentData((prevData) =>
                    prevData.filter((r) => r.id !== removedRow.id)
                  );
                } else {
                  setArchivedData((prevData) =>
                    prevData.filter((r) => r !== removedRow)
                  );
                }
              }
            }}
          />
        )}
        {/* ----- End of HotTable for Current Tab ----- */}

        {/* ----- HotTable for Archived Tab ----- */}
        {activeTab === 1 && (
          <HotTable
            ref={archivedHotTableRef}
            data={filteredArchivedData}
            licenseKey="non-commercial-and-evaluation"
            colHeaders={[
              'CPT Code',
              'Insurance',
              'Location', // 3rd
              'Master’s Amount', // 4th
              'Doctoral Amount', // 5th
              'Service Group',
              'Service Type',
              'Service Type Subtype',
              'Setting/Location Dependent',
              'Time Bound',
              'Min Time',
              'Max Time',
              'Amount Range',
              'Master’s Low Range',
              'Master’s High Range',
              'Doctoral Low Range',
              'Doctoral High Range',
              'Notes',
              'Effective Date',
              'End Date',
              'Updated Date',
            ]}
            columns={columns} // Use object-based columns
            fixedColumnsStart={2}
            hiddenColumns={{
              columns: hiddenColumns.map((col) =>
                columns.findIndex((c) => c.data === col)
              ),
              indicators: false,
            }}
            rowHeaders={true}
            rowHeights={75}
            width="1300"
            height="600"
            afterChange={handleAfterChangeArchived}
            minSpareRows={2}
            multiColumnSorting={true}
            wordWrap={true}
            manualRowResize={true}
            manualColumnResize={true}
            filters={false} // Disable built-in filters as we're handling them manually
            dropdownMenu={true}
            copyPaste={true} // Keep only one copyPaste prop
            contextMenu={contextMenuConfig} // Use the defined context menu config
            colWidths={150}
            cells={(row, col, prop) => {
              const cellProperties = {};
              cellProperties.renderer = (instance, td, ...rest) => {
                Handsontable.renderers.TextRenderer.apply(this, [
                  instance,
                  td,
                  ...rest,
                ]);
                td.style.fontSize = '15px';
                td.style.textAlign = 'center';
                td.style.verticalAlign = 'middle';
                td.style.whiteSpace = 'normal';
                td.style.wordWrap = 'break-word';
                td.style.padding = '7px';
              };
              return cellProperties;
            }}
            beforeRemoveRow={(index, amount) => {
              console.log(
                'Before remove row at index:',
                index,
                'Amount:',
                amount
              );

              // Determine the row to remove based on the active tab
              let removedRow;
              if (activeTab === 1) {
                removedRow = filteredCurrentData[index];
              } else {
                removedRow = filteredArchivedData[index];
              }

              console.log('Row to be removed:', removedRow);

              // Track the row ID for deletion if it exists
              const removedId = removedRow && removedRow.id;
              if (removedId) {
                setDeletedRowIds((prev) => [...prev, removedId]);
              }

              // Remove the row from the corresponding data array
              if (removedRow && removedRow.id) {
                if (activeTab === 1) {
                  setCurrentData((prevData) =>
                    prevData.filter((r) => r.id !== removedRow.id)
                  );
                } else {
                  setArchivedData((prevData) =>
                    prevData.filter((r) => r !== removedRow)
                  );
                }
              }
            }}
          />
        )}
        {/* ----- End of HotTable for Archived Tab ----- */}

        {/* ----- Update Entry Dialog ----- */}
        <Dialog open={isUpdateDialogOpen} onClose={closeUpdateDialog}>
          <DialogTitle>Create Updated Entry</DialogTitle>
          <DialogContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              minWidth: '400px',
            }}
          >
            {/* Masters Level Rate */}
            <TextField
              label="Masters Level Rate"
              type="number"
              value={mastersRate}
              onChange={(e) => setMastersRate(e.target.value)}
              required
              inputProps={{ min: 0, step: 'any' }}
            />

            {/* Doctoral Level Rate */}
            <TextField
              label="Doctoral Level Rate"
              type="number"
              value={doctoralRate}
              onChange={(e) => setDoctoralRate(e.target.value)}
              required
              inputProps={{ min: 0, step: 'any' }}
            />

            {/* Effective Date */}
            <DatePicker
              label="Effective Date"
              value={effectiveDate}
              onChange={(date) => setEffectiveDate(date)}
              renderInput={(params) => <TextField {...params} required />}
            />

            {/* New Row Expiration Date (Optional) */}
            <DatePicker
              label="Expiration Date (Optional) - New Row"
              value={expirationDate}
              onChange={(date) => setExpirationDate(date)}
              renderInput={(params) => <TextField {...params} />}
            />

            {/* Current Row Expiration Date (Optional) */}
            <TextField
              label="Current Row Expiration Date (Optional)"
              type="text"
              value={currentRowExpirationDate}
              onChange={(e) => setCurrentRowExpirationDate(e.target.value)}
              helperText="Enter YYYY-MM-DD or leave blank to keep the current row unchanged."
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeUpdateDialog} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={handleUpdateEntry}
              color="primary"
              variant="contained"
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
        {/* ----- End of Update Entry Dialog ----- */}

        {/* Save Changes Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{ marginTop: '20px' }}
        >
          Save Changes
        </Button>
      </div>
    </LocalizationProvider>
  );
}

export default memo(MyHandsontableComponent);
