import * as React from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Collapse,
  Typography,
  IconButton,
  Popover,
  Fade,
  Paper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
} from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import {
  AccessTimeTwoTone as AccessTimeTwoToneIcon,
  VerifiedUser as VerifiedUserIcon,
  ExpandMore as ExpandMoreIcon,
  PanTool as PanToolIcon,
  NoteAltOutlined as NoteAltOutlinedIcon,
  EventAvailable as EventAvailableIcon,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  brown,
  pink,
  green,
  blue,
  yellow,
  purple,
  grey,
} from '@mui/material/colors';

import moment from 'moment-timezone';
import { Col, Row } from 'reactstrap';
import PanToolWithTimer from '../UI/PanToolWithTimer';
// Temporarily Commenting it may be we need in future
import { isDST } from '../../common/utils/DateUtils';
import { getFirstCharacters } from '../../common/utils/StringUtils';
import styles from '../../styles/ProviderCard.module.css';
import { providerService } from '../../services';
import { useSnackbar } from 'notistack';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const theme = createTheme();

theme.typography.h3 = {
  fontSize: '1.6rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
};

// time 4 hours added to make it GMT
function formatSlotDateTime(date) {
  const startDateTime = moment(date);

  const isDst = isDST(startDateTime.toDate());

  // const hoursToAdd = isDst ? 4 : 5;

  const hoursToAdd = isDst ? 0 : 0;

  startDateTime.add(hoursToAdd, 'hours');

  const formattedStartDate = startDateTime.format('ddd M/DD/YY');
  const formattedStartTime = startDateTime
    .tz('America/New_York')
    .format('h:mmA'); // Have added that .tz here so that it always converts to EST time

  return `${formattedStartDate} ${formattedStartTime}`;
}

function getFrequencyAvatar(frequency) {
  switch (frequency) {
    case 'weekly':
      return {
        avatar: {
          letter: 'W',
          backgroundColor: grey[900],
          textColor: grey[50],
        },
      };
    case 'every_other_week':
      return {
        avatar: {
          letter: 'EOW',
          backgroundColor: pink[100],
          textColor: grey[50],
        },
      };
    case 'floater':
      return {
        avatar: {
          letter: 'FL',
          backgroundColor: brown[300],
          textColor: grey[50],
        },
      };
    default:
      return null;
  }
}

function getFrequencyText(frequency) {
  switch (frequency) {
    case 'weekly':
      return '(Weekly)';
    case 'every_other_week':
      return '(EOW)';
    case 'floater':
      return '(Floater)';
    default:
      return '';
  }
}

const getSlotAgeColor = (age) => {
  if (age >= 14 && age < 21) {
    return 'goldenrod';
  } else if (age >= 21 && age < 28) {
    return 'darkorange';
  } else if (age >= 28) {
    return 'red';
  }
  return 'transparent'; // default, in case it's used elsewhere
};

function getLocationDetails(slotOrLocation) {
  let location;
  let setting;

  if (typeof slotOrLocation === 'string') {
    location = slotOrLocation;
    setting = null;
  } else if (typeof slotOrLocation === 'object') {
    location = slotOrLocation.location;
    setting = slotOrLocation.setting;
  } else {
    return [{ avatar: { letter: 'Que', color: grey[500] } }]; // Default case
  }

  if (setting === 'Any Setting') {
    const locationAvatar = getLocationAvatar(location);
    if (locationAvatar) {
      return [
        { avatar: { letter: 'Rem', color: yellow[700] } },
        locationAvatar,
      ];
    }
    return [{ avatar: { letter: 'Rem', color: yellow[700] } }];
  } else if (setting === 'Remote Only') {
    return [{ avatar: { letter: 'Rem', color: yellow[700] } }];
  }

  return [getLocationAvatar(location)];
}

function getLocationAvatar(location) {
  switch (location) {
    case 'Baltimore':
      return { avatar: { letter: 'Bal', color: purple[500] } };
    case 'Silver Spring':
      return { avatar: { letter: 'SS', color: green[500] } };
    case 'Columbia':
      return { avatar: { letter: 'Col', color: blue[500] } };
    default:
      return null;
  }
}

const hasOldSlot = (provider, daysThreshold = 21) => {
  if (!provider?.slots || provider.slots.length === 0) return false;

  return provider.slots.some((slot) => {
    if (slot.type !== 'Hold' && slot.createdAt) {
      const slotAge = moment().diff(moment(slot.createdAt), 'days');
      return slotAge >= daysThreshold;
    }
    return false;
  });
};

// The below function is responsible for inner sorting i.e latest inner slot would come first and oldest would come at the last.
const sortObjectKeysBySlotStartTime = (obj) => {
  const currentDate = new Date();

  const keyValuePairs = Object.entries(obj).map(([key, value]) => {
    const slotDateTime = value[0] ? new Date(value[0].start_date_time) : null;
    const timeDifference = slotDateTime
      ? Math.abs(currentDate - slotDateTime)
      : Infinity;
    return { key, timeDifference, data: obj[key] };
  });

  keyValuePairs.sort((a, b) => a.timeDifference - b.timeDifference);

  const sortedArray = keyValuePairs.map((item) => ({
    [item.key]: item.data,
  }));

  return sortedArray;
};

const calculateDaysUntilExpiration = (expirationDate) => {
  const now = moment();
  const expiration = moment(expirationDate);
  const daysUntil = expiration.diff(now, 'days'); // Calculates the difference in full days
  const remainderHours = expiration
    .subtract(daysUntil, 'days')
    .diff(now, 'hours');
  // Check if the remainder hours are positive indicating that the next day hasn't started yet
  return remainderHours > 0 ? daysUntil + 1 : daysUntil;
};

export default function ProviderCard({
  step03FormValues,
  step05FormValues,
  title,
  image,
  getProviderYesMaybeText,
  getProviderDetail,
  obj,
  availableSlots,
  expandedOfToggle,
  onSlotClickHadler,
  isManual,
  entryTypeFormValues,
  onWithoutCalenderClickHandler,
  setSelectedProvider,
  isCardClickable,
  multiSlotConfirmed,
  setMultiSlotConfirmed,
  rows,
  setRows,
  selectSlot,
  deselectSlot,
  selectedSlots,
  testingApptCount,
  formConditionsMet,
  firstSlot,
  setFirstSlot,
  hasInteracted,
  setHasInteracted,
  serviceGroup,
  isTestingCheck,
  selectedProviderId,
  diagnosticSlotSelected,
}) {
  const provider = obj;
  console.log('Provider ID:', provider.id);
  console.log(
    'selectedProviderId:',
    selectedProviderId,
    typeof selectedProviderId
  );
  console.log('provider.id:', provider.id, typeof provider.id);

  let providerSlots = availableSlots.filter(
    (slot) => slot.provider_id === provider.id
  );

  const [expanded, setExpanded] = React.useState(false);
  const [isPsyPactMember, setIsPsyPactMember] = React.useState(false);
  const [licenseInOtherStates, setLicenseInOtherStates] = React.useState();
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);
  const [popoverHoldAnchorEl, setPopoverHoldAnchorEl] = React.useState({});
  const [popoverNoteAnchorEl, setPopoverNoteAnchorEl] = React.useState({});
  const popoverOpen = Boolean(popoverAnchorEl);
  const popoverId = popoverOpen ? 'popover-popover' : undefined;
  const holdOpen = Boolean(popoverHoldAnchorEl);
  const holdId = holdOpen ? 'hold-popover' : undefined;
  const noteOpen = Boolean(popoverNoteAnchorEl);
  const noteId = noteOpen ? 'note-popover' : undefined;

  const { enqueueSnackbar } = useSnackbar();

  // for new mutlipleSlots
  const [isClicked, setIsClicked] = React.useState(false);
  // Group provider slots by their provider_availability_id
  const groupedSlots = providerSlots.reduce((result, slot) => {
    const groupId = slot.provider_availability_id; // Assuming `provider_availability_id` is the grouping key
    if (!result[groupId]) {
      result[groupId] = [slot];
    } else {
      result[groupId].push(slot);
    }
    return result;
  }, {});

  const [isSelectButtonDisabled, setIsSelectButtonDisabled] =
    React.useState(true);

  const hasReachedSlotLimit =
    serviceGroup === 'Testing' &&
    testingApptCount &&
    Object.keys(selectedSlots).length >= testingApptCount;

  // Debugging logs
  console.log('Button Disabled State:', {
    formConditionsMet,
    selectedSlots,
    firstSlot,
    isSelectButtonDisabled,
    isTestingCheck,
    testingApptCount,
  });

  //end subsection of multipleSlots

  let providerOpens = providerSlots.filter((slot) => slot.type === 'Open');
  let uniqueOpenProviderAvailabilityIds = [
    ...new Set(providerOpens.map((open) => open.provider_availability_id)),
  ];
  let numberOfUniqueOpens = uniqueOpenProviderAvailabilityIds.length;
  let providerHolds = providerSlots.filter((slot) => slot.type === 'Hold');
  let uniqueProviderAvailabilityIds = [
    ...new Set(providerHolds.map((hold) => hold.provider_availability_id)),
  ];
  let numberOfUniqueHolds = uniqueProviderAvailabilityIds.length;
  let frequencies = providerSlots.map((slot) => slot.frequency);
  let uniqueFrequencies = [...new Set(frequencies)];

  const locationDetailsArray = providerSlots.flatMap((slot) =>
    getLocationDetails(slot)
  );

  const uniqueLocationDetailsArray = locationDetailsArray.reduce(
    (uniqueArray, locationDetails) => {
      const isDuplicate = uniqueArray.some(
        (ld) =>
          ld.avatar.letter === locationDetails.avatar.letter &&
          ld.avatar.color === locationDetails.avatar.color
      );

      if (!isDuplicate) {
        uniqueArray.push(locationDetails);
      }

      return uniqueArray;
    },
    []
  );

  // Calculate the number of unique slots that are 14 days or older and are of type 'opening'
  const now = moment();

  const numberOfUniqueOlderSlots = Object.values(groupedSlots).reduce(
    (count, slotGroup) => {
      // Filter out holds and get only opening slots
      const openingsOnly = slotGroup.filter((slot) => slot.type !== 'Hold');

      if (!openingsOnly.length) return count; // If there are no openings in this group, just continue

      const firstOpeningSlot = openingsOnly[0]; // Consider the first opening slot from the group
      const createdAt = moment(firstOpeningSlot.createdAt);
      const slotAgeInDays = now.diff(createdAt, 'days');

      return slotAgeInDays >= 14 ? count + 1 : count;
    },
    0
  );

  const isTopIssuesIncludeInSevice = (issue) => {
    let check =
      getProviderYesMaybeText(obj)?.provider?.therapy?.topIssues?.[issue];
    if (step03FormValues?.services?.length > 0)
      return step03FormValues?.services?.some((v) => v.title === check);
    else {
      return false;
    }
  };

  const sortedInnerSlots = sortObjectKeysBySlotStartTime(groupedSlots);

  const renderStateBadges = () => {
    const states = [];
    const selectedState = step05FormValues?.provider_other_licensed_state;
    if (licenseInOtherStates?.includes(selectedState)) {
      states.push(selectedState);
    }
    // Check if the provider is a PsyPact member and add the PsyPact badge, excluding "MD Only" and no state selected cases
    if (isPsyPactMember && selectedState && selectedState !== 'MD Only') {
      states.push('PsyPact');
    }
    return states.map((state, index) => (
      <Box
        key={index}
        sx={{
          display: 'flex',
          alignItems: 'center',
          bgcolor: state === 'PsyPact' ? green[500] : purple[500], // Green for PsyPact, purple for others
          color: '#fff',
          padding: '2px 8px',
          borderRadius: '12px',
          marginLeft: '4px',
          fontSize: '0.75rem',
        }}
      >
        <VerifiedUserIcon sx={{ marginRight: '4px', fontSize: '1rem' }} />
        {state}
      </Box>
    ));
  };

  // handles
  const handleClick = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleChange = (panel, isExpanded) => {
    if (isExpanded === true) {
      setExpanded(panel);
    } else if (isExpanded === panel) {
      setExpanded(true);
    } else if (isExpanded !== panel) {
      setExpanded(panel);
    }
  };

  const handleCardClick = async () => {
    if (isCardClickable) {
      try {
        const providerDetails = await providerService.getProviderDetail(
          obj.id,
          obj.email
        );
        if (providerDetails && providerDetails.data) {
          // Pass the details up to the parent component
          setSelectedProvider(
            providerDetails.data.provider.degrees_and_license
          );
        } else {
          console.log('No provider details found');
        }
      } catch (error) {
        console.error('Failed to fetch provider details', error);
      }
      setIsClicked(true); // Set card as clicked
    }
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const handleNotePopoverClose = (slotId) => {
    setPopoverNoteAnchorEl((prevState) => ({
      ...prevState,
      [slotId]: null,
    }));
  };

  const handleHoldPopoverClose = (slotId) => {
    setPopoverHoldAnchorEl((prevState) => ({
      ...prevState,
      [slotId]: null,
    }));
  };

  const handleHoldClick = (event, slotId) => {
    setPopoverHoldAnchorEl((prevState) => ({
      ...prevState,
      [slotId]: event.currentTarget,
    }));
  };

  const handleNoteClick = (event, slotId) => {
    setPopoverNoteAnchorEl((prevState) => ({
      ...prevState,
      [slotId]: event.currentTarget,
    }));
  };

  // Trello Card FE - Select Button / Date and Time Clickable Removed...//
  const handleSelectButtonClick = async (slot) => {
    setHasInteracted(true);

    // Check if only one appointment is being scheduled
    if (!isTestingCheck) {
      if (selectedSlots[slot.id]) {
        enqueueSnackbar('Cannot select a slot twice', { variant: 'warning' });
        console.warn('Attempt to select an already selected slot:', slot.id);
        return; // Prevent further action if the slot is already selected
      }

      await onSlotClickHadler(slot, provider, title);
      selectSlot(slot.id, slot);
      return; // Exit after handling single appointment selection
    }

    // Calculate the duration of the slot being selected
    const slotDuration = moment(slot.end_date_time).diff(
      moment(slot.start_date_time),
      'hours',
      true
    );

    // Diagnostic Session can be 2 hours
    const isDiagnosticSlot = slotDuration <= 2.0;

    // Testing Session can be 2 hours or greater than 2 hours
    const isTestingSlot = slotDuration > 2.0;

    // Sort selected slots by their chronological order
    const selectedSlotsList = Object.values(selectedSlots).sort(
      (a, b) => new Date(a.start_date_time) - new Date(b.start_date_time)
    );

    // Check Diagnostic-before-Testing rule using chronological order
    if (selectedSlotsList.length > 0) {
      const earliestDiagnosticSlot = selectedSlotsList.find(
        (s) =>
          moment(s.end_date_time).diff(
            moment(s.start_date_time),
            'hours',
            true
          ) <= 2.0
      ); // Filtering the Diagnostic Session here

      const earliestTestingSlot = selectedSlotsList.find(
        (s) =>
          moment(s.end_date_time).diff(
            moment(s.start_date_time),
            'hours',
            true
          ) > 2.0
      ); // Filtering the earlist Testing Session here

      // Validate order
      if (isDiagnosticSlot && earliestTestingSlot) {
        // User is trying to select a Diagnostic slot AFTER a Testing slot
        if (
          new Date(slot.start_date_time) >
          new Date(earliestTestingSlot.start_date_time)
        ) {
          enqueueSnackbar(
            'Diagnostic slot must always be selected before Testing slots. Please select slots in the correct order.',
            { variant: 'warning', timeout: 3000 }
          );
          console.warn('Diagnostic slot selected after Testing slot:', slot);
          return;
        }
      }

      if (isTestingSlot && earliestDiagnosticSlot) {
        // User is trying to select a Testing slot BEFORE a Diagnostic slot
        if (
          new Date(slot.start_date_time) <
          new Date(earliestDiagnosticSlot.start_date_time)
        ) {
          enqueueSnackbar(
            'Diagnostic slot must always be selected before Testing slots. Please select slots in the correct order.',
            { variant: 'warning', timeout: 3000 }
          );
          console.warn('Testing slot selected before Diagnostic slot:', slot);
          return;
        }
      }
    }

    // Diagnostic and testing slot selection logic based on selectedSlots and testingApptCount
    const diagnosticSlotSelected = Object.values(selectedSlots).some(
      (s) =>
        moment(s.end_date_time).diff(
          moment(s.start_date_time),
          'hours',
          true
        ) <= 2.0
    ); // Filtering Diagnostic Slot here

    const testingSlotsSelected = Object.values(selectedSlots).filter(
      (s) =>
        moment(s.end_date_time).diff(moment(s.start_date_time), 'hours', true) >
        2.0
    ); // Filtering Testing Slot here

    if (diagnosticSlotSelected && isDiagnosticSlot) {
      enqueueSnackbar(
        'Diagnostic session already selected. If you prefer this slot, please deselect the other slot first.',
        { variant: 'warning', timeout: 3000 }
      );
      return; // Prevent selecting another Diagnostic slot if one is already selected
    }

    if (isTestingSlot) {
      if (testingApptCount === '2' && testingSlotsSelected.length >= 1) {
        enqueueSnackbar(
          'Only one Testing Session slot is allowed for a 2-slot appointment',
          { variant: 'warning', timeout: 3000 }
        );
        return; // Prevent selecting more than one testing slot for two appointments
      } else if (testingApptCount === '3' && testingSlotsSelected.length >= 2) {
        enqueueSnackbar(
          'Only two Testing Session slots are allowed for a 3-slot appointment',
          { variant: 'warning', timeout: 3000 }
        );
        return; // Prevent selecting more than two testing slots for three appointments
      }
    }

    if (selectedSlots[slot.id]) {
      enqueueSnackbar('Cannot select a slot twice', { variant: 'warning' });
      console.warn('Attempt to select an already selected slot:', slot.id);
      return; // Stop further execution if the slot is already selected
    }

    // Proceed to select the slot if all checks pass
    await onSlotClickHadler(slot, provider, title);
    selectSlot(slot.id, slot);
  };

  // useEffects
  React.useEffect(() => {
    setExpanded(expandedOfToggle);
  }, [expandedOfToggle]);

  React.useEffect(() => {
    async function getProviderDetaiFromAPI() {
      const response = await getProviderDetail(obj.id, obj.email);
      setIsPsyPactMember(
        response?.data?.provider?.psyPactDetails?.isPsyPactMember === 'yes'
          ? true
          : false
      );
      setLicenseInOtherStates(
        response?.data?.provider?.licensed_in_other_states?.split(',')
      );
    }
    getProviderDetaiFromAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obj]);

  // for mutipleSlots
  React.useEffect(() => {
    const isTestingCountInvalid =
      testingApptCount === null ||
      testingApptCount === undefined ||
      testingApptCount === '';

    // This line will help us understand if it's really necessary to depend on hasInteracted and firstSlot
    const shouldDisableDueToNoSlotsSelected =
      Object.keys(selectedSlots).length === 0 && hasInteracted;

    const isProviderMismatch =
      serviceGroup === 'Testing' &&
      isTestingCheck &&
      selectedProviderId &&
      selectedProviderId.toString() !== provider.id.toString();

    const isDisabled =
      !formConditionsMet || // Form conditions are not met
      (serviceGroup === 'Testing' && isTestingCheck && isTestingCountInvalid) || // Testing and no valid radio button is selected
      shouldDisableDueToNoSlotsSelected || // Check for slots if form conditions are met
      isProviderMismatch; // Disable if provider is not the selected provider

    setIsSelectButtonDisabled(isDisabled);

    console.log('Evaluating isSelectButtonDisabled:', isDisabled);
    console.log('Reasons:', {
      formConditionsMet: !formConditionsMet,
      testingCheck:
        serviceGroup === 'Testing' && isTestingCheck && isTestingCountInvalid,
      noSlotsSelected: shouldDisableDueToNoSlotsSelected,
      hasReachedSlotLimit,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formConditionsMet,
    serviceGroup,
    isTestingCheck,
    testingApptCount,
    selectedSlots,
    hasInteracted,
    selectedProviderId, // Add selectedProviderId to dependencies
    provider.id, // Optionally add provider.id if it can change
  ]);

  if (!isManual) {
    if (uniqueFrequencies.length > 0) {
      // eslint-disable-next-line no-unused-expressions
      return (
        <div>
          <Row className="g-2">
            <div className="col-12 relative-container">
              <Col xs={12}>
                <div style={{ position: 'relative' }}>
                  {getProviderYesMaybeText(obj)?.provider?.therapy
                    ?.providerPriorityScheduling ? (
                    <div className={styles.priorityRibbon}>Priority</div>
                  ) : hasOldSlot(obj) ? (
                    <div className={styles.oldSlotRibbon}>+21-Slot</div>
                  ) : null}
                </div>
                <div style={{ width: '100%' }}>
                  <Card
                    onClick={handleCardClick}
                    style={{
                      width: '100%',
                      backgroundColor: '#f5f5f5',
                      minHeight: '125px',
                      cursor: isCardClickable ? 'pointer' : 'default', // Change cursor to pointer if clickable
                      position: 'relative', // Relative positioning for overlay
                    }}
                  >
                    {isCardClickable && !isClicked && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '10px', // Position towards the top
                          left: '50%',
                          transform: 'translateX(-50%)', // Center horizontally
                          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Grey overlay
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: 'white',
                          fontSize: '1.2em',
                          padding: '5px 10px', // Optional: add some padding for better visibility
                          zIndex: 1, // Ensure overlay is on top
                        }}
                      >
                        Click to Update Fee with License
                      </div>
                    )}

                    <CardHeader
                      style={{ height: '50px' }}
                      avatar={
                        <React.Fragment>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              margin: -10,
                              justifyContent: 'space-between',
                              marginLeft:
                                getProviderYesMaybeText(obj)?.provider?.therapy
                                  ?.providerPriorityScheduling ||
                                hasOldSlot(obj)
                                  ? 13
                                  : -10,
                            }}
                          >
                            {!isManual &&
                              uniqueLocationDetailsArray?.map(
                                (locationDetails) => (
                                  <Col
                                    xs={3}
                                    key={locationDetails.avatar.letter}
                                  >
                                    <Box
                                      sx={{
                                        bgcolor: locationDetails.avatar.color,
                                        fontSize: '12px',
                                        width: 26,
                                        height: 26,
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                      aria-label={provider.name}
                                    >
                                      {locationDetails.avatar.letter}
                                    </Box>
                                  </Col>
                                )
                              )}
                          </div>
                          {renderStateBadges()}{' '}
                          {/* This line renders the badges */}
                        </React.Fragment>
                      }
                      action={
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            paddingTop: -10,
                            paddingRight: 10,
                          }}
                        >
                          {!isManual &&
                            uniqueFrequencies?.map((frequency) => {
                              let frequencyDetails =
                                getFrequencyAvatar(frequency);
                              return (
                                <Box
                                  key={frequencyDetails?.avatar?.letter}
                                  sx={{
                                    bgcolor:
                                      frequencyDetails?.avatar?.backgroundColor, // Sets the background color
                                    color: frequencyDetails?.avatar?.textColor,
                                    fontSize: '11px',
                                    width: 26,
                                    height: 26,
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                  aria-label={frequency}
                                >
                                  {frequencyDetails?.avatar?.letter}
                                </Box>
                              );
                            })}
                        </div>
                      }
                    />

                    <div style={{ marginTop: '-10px', textAlign: 'center' }}>
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="body"
                          style={{ fontWeight: 'bold' }}
                        >
                          {title}
                        </Typography>
                      </ThemeProvider>
                    </div>

                    <div style={{ marginTop: '5px' }}>
                      <CardMedia
                        component="img"
                        height="194"
                        image={image}
                        alt="Provider img"
                      />
                    </div>
                    <CardActions disableSpacing style={{ marginTop: '-10px' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                          marginTop: '5px',
                        }}
                      >
                        <div style={{ alignItems: 'center' }}>
                          {isManual && (
                            <Row
                              style={{
                                color: 'red',
                                height: '28px',
                                cursor: 'pointer',
                                marginTop: '10px',
                                paddingLeft: '10px',
                                marginBottom: '10px',
                                paddingRight: '10px',

                                width: '100%',
                              }}
                              onClick={async (e) => {
                                onWithoutCalenderClickHandler(obj, title);
                              }}
                            >
                              {!(
                                entryTypeFormValues.entryType === 'existing' &&
                                entryTypeFormValues.existingClientOptions ===
                                  'Turning 18 years-Old'
                              ) ? (
                                'Schedule appointment without Calendar availability'
                              ) : (
                                <div>
                                  <Button>Select</Button>
                                </div>
                              )}
                            </Row>
                          )}

                          {getProviderYesMaybeText &&
                            obj &&
                            getProviderYesMaybeText(obj).out.length > 0 && (
                              <Button
                                variant="contained"
                                onClick={handleClick}
                                style={{
                                  height: '20px',
                                  width: '100%',
                                  margin: '20px auto 0',
                                }}
                              >
                                Service Alert
                              </Button>
                            )}

                          <Popover
                            id={popoverId}
                            open={popoverOpen}
                            anchorEl={popoverAnchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                            TransitionComponent={Fade}
                            transitionDuration={350}
                          >
                            <Paper>
                              <Typography sx={{ p: 4 }}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      getProviderYesMaybeText &&
                                      obj &&
                                      getProviderYesMaybeText(obj).out,
                                  }}
                                ></div>
                              </Typography>
                            </Paper>
                          </Popover>
                        </div>
                      </div>
                    </CardActions>

                    <CardActions>
                      {!isManual && numberOfUniqueOpens > 0 && (
                        <Badge
                          sx={{
                            '.MuiBadge-badge': {
                              background:
                                numberOfUniqueOlderSlots > 0
                                  ? `linear-gradient(to right, green ${(
                                      (numberOfUniqueOpens.toString().length /
                                        (numberOfUniqueOpens.toString().length +
                                          numberOfUniqueOlderSlots.toString()
                                            .length)) *
                                      100
                                    ).toFixed(0)}%, orange ${(
                                      (numberOfUniqueOpens.toString().length /
                                        (numberOfUniqueOpens.toString().length +
                                          numberOfUniqueOlderSlots.toString()
                                            .length)) *
                                      100
                                    ).toFixed(0)}%)`
                                  : 'green',
                              color: 'white',
                              fontWeight: 'bold',
                              padding: '0 6px',
                              fontSize: '0.75rem',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '24px', // Ensuring a consistent height
                              minWidth: '40px', // Ensuring a minimum width for clear visibility
                              whiteSpace: 'nowrap', // Prevent text wrapping
                              overflow: 'hidden', // Prevent overflow of text
                            },
                          }}
                          badgeContent={
                            numberOfUniqueOlderSlots > 0
                              ? `${numberOfUniqueOpens}  ${numberOfUniqueOlderSlots}`
                              : numberOfUniqueOpens
                          }
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          <IconButton>
                            <EventAvailableIcon
                              style={{ width: '22px', height: '22px' }}
                              sx={{ color: 'green' }}
                            />
                          </IconButton>
                        </Badge>
                      )}
                      <Box
                        sx={{
                          color: 'action.active',
                          display: 'flex',
                          flexDirection: 'column',
                          '& > *': {
                            marginBottom: '2px',
                          },
                          '& .MuiBadge-root': {
                            marginRight: 4,
                            marginTop: '5px', // Adjust the marginTop value to move the Badge further down
                          },
                        }}
                      >
                        {!isManual && numberOfUniqueHolds > 0 && (
                          <Badge
                            badgeContent={numberOfUniqueHolds}
                            color="error"
                            sx={{ '& .MuiBadge-badge': { marginTop: '5px' } }}
                          >
                            <IconButton>
                              <PanToolIcon
                                style={{ width: '18px', height: '18px' }}
                                sx={{ color: 'red' }}
                              />
                            </IconButton>
                          </Badge>
                        )}{' '}
                      </Box>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'end',
                        }}
                      >
                        {isTopIssuesIncludeInSevice('issue3') && (
                          <Typography style={{ fontSize: '2em' }}>
                            <span role="img" aria-label="note">
                              🥉
                            </span>
                          </Typography>
                        )}
                        {isTopIssuesIncludeInSevice('issue2') && (
                          <Typography style={{ fontSize: '2em' }}>
                            <span role="img" aria-label="note">
                              🥈
                            </span>
                          </Typography>
                        )}
                        {isTopIssuesIncludeInSevice('issue1') && (
                          <Typography style={{ fontSize: '2em' }}>
                            <span role="img" aria-label="note">
                              🥇
                            </span>
                          </Typography>
                        )}
                      </div>

                      {!isManual &&
                        !Object.values(groupedSlots).length === 0 && (
                          <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        )}
                    </CardActions>

                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                      <CardContent style={{ width: '100%' }}>
                        {sortedInnerSlots.map((sortedInnerObj) => {
                          return Object.values(sortedInnerObj).map(
                            (slotGroup, groupIndex) => {
                              const groupFirstSlot = slotGroup[0]; // Renamed from firstSlot to groupFirstSlot for multiSlot
                              const isFirstSlotGroup = true;

                              // Calculate the slot age in days
                              const createdAt = moment(
                                groupFirstSlot.createdAt
                              );

                              // Using updatedAt if createdAt is not available
                              const updatedAt = groupFirstSlot.updatedAt
                                ? moment(groupFirstSlot.updatedAt)
                                : createdAt; // Use createdAt if updatedAt is not set

                              const now = moment();

                              // Calculating Slot age
                              const slotAgeInDays = now.diff(createdAt, 'days');

                              const updatedSlotAgeInDays = now.diff(
                                updatedAt,
                                'days'
                              );

                              const daysUntilExpiration =
                                calculateDaysUntilExpiration(
                                  groupFirstSlot.holdExpirationDateTime
                                );

                              // ✅ Displaying slot age based on updatedAt logic
                              let slotAgeDisplay;
                              if (
                                slotAgeInDays < 1 &&
                                updatedSlotAgeInDays < 1
                              ) {
                                slotAgeDisplay = 'Brand New - < 1 day';
                              } else if (
                                slotAgeInDays === updatedSlotAgeInDays
                              ) {
                                slotAgeDisplay = `(${slotAgeInDays} days old)`;
                              } else {
                                const updatedAgeText =
                                  updatedSlotAgeInDays < 1
                                    ? '< 1 day'
                                    : `${updatedSlotAgeInDays} days old`;
                                slotAgeDisplay = `(${slotAgeInDays} days old /\n${updatedAgeText})`;
                              }
                              return (
                                <Accordion
                                  key={`group${groupIndex}`}
                                  expanded={expanded === `group${groupIndex}`}
                                  style={{ width: '100%' }}
                                >
                                  <AccordionSummary
                                    expandIcon={
                                      slotGroup.length > 1 && (
                                        <ExpandMoreIcon
                                          onClick={() =>
                                            handleChange(
                                              `group${groupIndex}`,
                                              expanded
                                            )
                                          }
                                        />
                                      )
                                    }
                                    aria-controls={`group${groupIndex}-content`}
                                    id={`group${groupIndex}-header`}
                                    className={`${
                                      styles.slotSummaryContainer
                                    } ${
                                      slotAgeInDays >= 21
                                        ? styles.agedScroll
                                        : ''
                                    }`}
                                    sx={{
                                      '& .MuiAccordionSummary-content': {
                                        flexDirection: 'column', // Set flex direction to column
                                        alignItems: 'center', // Center align the content
                                      },
                                      '& .MuiAccordionSummary-expandIconWrapper':
                                        {
                                          alignSelf: 'center', // Center the expand icon
                                          marginTop: '-15px', // Add margin to reduce empty space
                                        },
                                    }}
                                    style={{
                                      width: '100%',
                                      minHeight: '120px',
                                    }}
                                  >
                                    {/* 1. Time and Select Button */}
                                    <div
                                      className={styles.slotTimeRow}
                                      style={{
                                        alignItems: 'center',
                                        position: 'relative',
                                      }}
                                    >
                                      {/* Typography for displaying the slot time */}
                                      <Typography
                                        sx={{
                                          fontSize: '14px',
                                          marginBottom: '8px',
                                          textAlign: 'center',
                                        }}
                                        // for multipleSlot to use for regular select
                                        // onClick={() => {
                                        //   onSlotClickHadler(
                                        //     {
                                        //       ...groupFirstSlot,
                                        //       start_date_time: moment(
                                        //         formatSlotDateTime(
                                        //           groupFirstSlot.start_date_time
                                        //         ),
                                        //         "ddd M/DD/YY h:mmA"
                                        //       ).format("YYYY-MM-DDTHH:mm"),
                                        //       end_date_time: moment(
                                        //         formatSlotDateTime(
                                        //           groupFirstSlot.end_date_time
                                        //         ),
                                        //         "ddd M/DD/YY h:mmA"
                                        //       ).format("YYYY-MM-DDTHH:mm"),
                                        //     },
                                        //     obj,
                                        //     title
                                        //   );
                                        // }}
                                      >
                                        {formatSlotDateTime(
                                          groupFirstSlot?.start_date_time
                                        )}
                                      </Typography>

                                      {/* Select Button */}
                                      {/* Form multiSlot*/}
                                      <div
                                        onClick={() => {
                                          if (isSelectButtonDisabled) {
                                            // Show the appropriate alert
                                            if (!formConditionsMet) {
                                              enqueueSnackbar(
                                                'Please fill out all required fields',
                                                {
                                                  variant: 'error',
                                                }
                                              );
                                            } else if (
                                              serviceGroup === 'Testing' &&
                                              isTestingCheck &&
                                              !testingApptCount
                                            ) {
                                              enqueueSnackbar(
                                                'Please select either 2 or 3 Appts',
                                                {
                                                  variant: 'error',
                                                }
                                              );
                                            }
                                          }
                                        }}
                                        style={{
                                          display: 'inline-block',
                                          position: 'relative',
                                        }}
                                      >
                                        <Button
                                          variant={
                                            selectedSlots[groupFirstSlot?.id]
                                              ? 'outlined'
                                              : 'contained'
                                          }
                                          size="small"
                                          onClick={() => {
                                            if (!isSelectButtonDisabled) {
                                              handleSelectButtonClick(
                                                groupFirstSlot
                                              );
                                            }
                                          }}
                                          disabled={
                                            isSelectButtonDisabled ||
                                            (hasReachedSlotLimit &&
                                              !selectedSlots[groupFirstSlot.id])
                                          }
                                          style={{
                                            marginBottom: '8px',
                                            backgroundColor: selectedSlots[
                                              groupFirstSlot?.id
                                            ]
                                              ? 'gray'
                                              : '',
                                            cursor:
                                              isSelectButtonDisabled ||
                                              (hasReachedSlotLimit &&
                                                !selectedSlots[
                                                  groupFirstSlot.id
                                                ])
                                                ? 'not-allowed'
                                                : 'pointer',
                                          }}
                                        >
                                          Select
                                        </Button>
                                      </div>

                                      {/* Deselect IconButton */}
                                      {selectedSlots[groupFirstSlot?.id] &&
                                        serviceGroup !== 'Therapy' &&
                                        (serviceGroup !== 'Testing' ||
                                          isTestingCheck) && (
                                          <IconButton
                                            onClick={() => {
                                              deselectSlot(groupFirstSlot.id); // Deselect the slot
                                            }}
                                            size="small"
                                            style={{
                                              color: 'red',
                                              position: 'absolute',
                                              right: 5,
                                              top: 25,
                                            }}
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        )}
                                    </div>

                                    {/* End of multiSlot subsection*/}

                                    {/* 2. Frequency Text and Location Icon */}
                                    <div
                                      className={styles.locationFrequencyRow}
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: '8px',
                                      }}
                                    >
                                      {/* Location Icon */}
                                      {getLocationDetails(groupFirstSlot).map(
                                        (locationDetail, index) => (
                                          <Box
                                            key={index}
                                            sx={{
                                              bgcolor:
                                                locationDetail.avatar.color,
                                              fontSize: '8px',
                                              width: 18,
                                              height: 18,
                                              borderRadius: '50%',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              marginRight: '8px',
                                            }}
                                            aria-label={
                                              locationDetail.avatar.letter
                                            }
                                          >
                                            {locationDetail.avatar.letter}
                                          </Box>
                                        )
                                      )}

                                      {/* Frequency Text */}
                                      {getFrequencyText(
                                        groupFirstSlot.frequency
                                      ) && (
                                        <span style={{ fontWeight: 'bold' }}>
                                          {getFrequencyText(
                                            groupFirstSlot.frequency
                                          )}
                                        </span>
                                      )}
                                    </div>

                                    {/* 3. Slot Age Icon and Text */}
                                    <div
                                      className={styles.slotAgeRow}
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: '4px', // Reduced marginTop
                                        marginBottom: '0px', // Ensure no marginBottom
                                      }}
                                    >
                                      {/* Slot Age Icon (if applicable) */}
                                      {slotAgeInDays >= 14 && (
                                        <IconButton>
                                          <AccessTimeTwoToneIcon
                                            style={{
                                              color:
                                                getSlotAgeColor(slotAgeInDays),
                                            }}
                                          />
                                        </IconButton>
                                      )}

                                      {/* Slot Age Text (always shown) */}
                                      {isFirstSlotGroup && (
                                        <Typography
                                          style={{
                                            fontSize: '10px',
                                            color: 'grey',
                                            marginLeft: '4px',
                                          }}
                                        >
                                          {slotAgeDisplay}
                                        </Typography>
                                      )}
                                    </div>

                                    {/* 4. PanToolIcon and/or Note Icon */}
                                    {(groupFirstSlot?.type === 'Hold' ||
                                      (groupFirstSlot?.type === 'Open' &&
                                        groupFirstSlot.note)) && (
                                      <div
                                        className={styles.iconRow}
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          marginTop: '0px', // Reduced marginTop
                                        }}
                                      >
                                        {/* PanToolIcon */}
                                        {groupFirstSlot?.type === 'Hold' && (
                                          <IconButton
                                            onClick={(event) =>
                                              handleHoldClick(
                                                event,
                                                groupFirstSlot.id
                                              )
                                            }
                                            style={{ marginRight: '8px' }}
                                          >
                                            {groupFirstSlot.holdExpirationDateTime ? (
                                              <PanToolWithTimer
                                                daysUntilExpiration={
                                                  daysUntilExpiration
                                                }
                                                style={{
                                                  width: '30px',
                                                  height: '30px',
                                                }}
                                                sx={{ color: 'red' }}
                                              />
                                            ) : (
                                              <PanToolIcon
                                                style={{
                                                  width: '30px',
                                                  height: '30px',
                                                }}
                                                sx={{ color: 'red' }}
                                              />
                                            )}
                                          </IconButton>
                                        )}

                                        {/* Note Icon */}
                                        {groupFirstSlot?.type === 'Open' &&
                                          groupFirstSlot.note && (
                                            <IconButton
                                              onClick={(event) =>
                                                handleNoteClick(
                                                  event,
                                                  groupFirstSlot.id
                                                )
                                              }
                                            >
                                              <NoteAltOutlinedIcon
                                                style={{
                                                  width: '30px',
                                                  height: '30px',
                                                }}
                                                sx={{ color: 'blue' }}
                                              />
                                            </IconButton>
                                          )}
                                      </div>
                                    )}

                                    {/* Popover for Note Icon */}
                                    <Popover
                                      id={noteId}
                                      open={Boolean(
                                        popoverNoteAnchorEl[groupFirstSlot.id]
                                      )}
                                      anchorEl={
                                        popoverNoteAnchorEl[groupFirstSlot.id]
                                      }
                                      onClose={() =>
                                        handleNotePopoverClose(
                                          groupFirstSlot.id
                                        )
                                      }
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                      }}
                                      TransitionComponent={Fade}
                                      transitionDuration={350}
                                    >
                                      <Paper>
                                        <Typography sx={{ p: 4 }}>
                                          {groupFirstSlot.note}
                                          {/* Add slot history if the hold has expired and the slot is now open */}
                                          {groupFirstSlot.holdExpirationDateTime &&
                                            groupFirstSlot.type === 'Open' && (
                                              <p
                                                style={{
                                                  marginTop: '10px',
                                                  fontWeight: 'bold',
                                                }}
                                              >
                                                Slot was previously a hold that
                                                expired on{' '}
                                                {moment
                                                  .utc(
                                                    groupFirstSlot.holdExpirationDateTime
                                                  )
                                                  .format('MM/DD/YYYY HH:mm')}
                                              </p>
                                            )}
                                        </Typography>
                                      </Paper>
                                    </Popover>

                                    {/* Popover for PanToolIcon */}
                                    <Popover
                                      id={holdId}
                                      open={Boolean(
                                        popoverHoldAnchorEl[groupFirstSlot.id]
                                      )}
                                      anchorEl={
                                        popoverHoldAnchorEl[groupFirstSlot.id]
                                      }
                                      onClose={() =>
                                        handleHoldPopoverClose(
                                          groupFirstSlot.id
                                        )
                                      }
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                      }}
                                      TransitionComponent={Fade}
                                      transitionDuration={350}
                                    >
                                      <Paper>
                                        <Typography
                                          sx={{ p: 4 }}
                                          component={'div'}
                                        >
                                          <p>
                                            {`Hold Created By ${getFirstCharacters(
                                              groupFirstSlot?.person_creating_hold
                                            )} on ${moment(
                                              groupFirstSlot?.holdCreationDate
                                            ).format('MM/DD/YY')} RE -${
                                              groupFirstSlot?.clientInitials
                                            }`}
                                          </p>

                                          {/* Conditionally display the expiration date if it exists */}
                                          {groupFirstSlot?.holdExpirationDateTime && (
                                            <p
                                              style={{
                                                textAlign: 'center',
                                                color: 'red',
                                              }}
                                            >
                                              {`Hold Expires on ${moment(
                                                formatSlotDateTime(
                                                  groupFirstSlot?.holdExpirationDateTime
                                                ),
                                                'ddd M/DD/YY h:mmA'
                                              ).format('MM/DD/YY hh:mm A')}`}
                                            </p>
                                          )}

                                          <p
                                            style={{
                                              textAlign: 'center',
                                            }}
                                          >
                                            {groupFirstSlot?.note}
                                          </p>
                                        </Typography>
                                      </Paper>
                                    </Popover>
                                  </AccordionSummary>

                                  <AccordionDetails
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      padding: '10px 0',
                                      height:
                                        expanded === `group${groupIndex}`
                                          ? 'auto'
                                          : '40px',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    {/* multiSlot adjustment -Display the remaining slots in the Accordion Details */}
                                    {slotGroup
                                      .slice(1)
                                      .map((slot, slotIndex) => {
                                        // Calculate slotDuration for this slot
                                        const slotDuration = moment(
                                          slot.end_date_time
                                        ).diff(
                                          moment(slot.start_date_time),
                                          'hours',
                                          true
                                        );

                                        return (
                                          <div
                                            key={slotIndex}
                                            className={`${styles.slotDetails} ${
                                              slot.selected
                                                ? styles.selectedSlot
                                                : ''
                                            }`}
                                          >
                                            <Typography
                                              sx={{
                                                flexShrink: 0,
                                                fontSize: '14px',
                                                height: '30px',
                                                textAlign: 'center',
                                              }}
                                              // Would need to move this to Select for non MultiSlot
                                              // onClick={async () => {
                                              //   // Your existing onClick logic
                                              //   onSlotClickHadler(
                                              //     {
                                              //       ...slot,
                                              //       start_date_time: moment(
                                              //         formatSlotDateTime(
                                              //           slot.start_date_time
                                              //         ),
                                              //         "ddd M/DD/YY h:mmA"
                                              //       ).format(
                                              //         "YYYY-MM-DDTHH:mm"
                                              //       ),
                                              //       end_date_time: moment(
                                              //         formatSlotDateTime(
                                              //           slot.end_date_time
                                              //         ),
                                              //         "ddd M/DD/YY h:mmA"
                                              //       ).format(
                                              //         "YYYY-MM-DDTHH:mm"
                                              //       ),
                                              //     },
                                              //     obj,
                                              //     title
                                              //   );
                                              // }}
                                            >
                                              <span
                                                style={{ cursor: 'pointer' }}
                                              >
                                                {formatSlotDateTime(
                                                  slot.start_date_time
                                                )}
                                              </span>
                                            </Typography>

                                            <Button
                                              variant={
                                                selectedSlots[slot.id]
                                                  ? 'outlined'
                                                  : 'contained'
                                              }
                                              size="small"
                                              color="primary"
                                              className={
                                                styles.slotDetailButton
                                              }
                                              onClick={async () => {
                                                if (!isSelectButtonDisabled) {
                                                  handleSelectButtonClick(slot);
                                                }
                                              }}
                                              disabled={
                                                isSelectButtonDisabled ||
                                                (hasReachedSlotLimit &&
                                                  !selectedSlots[slot.id]) ||
                                                (diagnosticSlotSelected &&
                                                  slotDuration <= 1.0 &&
                                                  !selectedSlots[slot.id])
                                              }
                                              style={{
                                                marginBottom: '8px',
                                                backgroundColor: selectedSlots[
                                                  slot.id
                                                ]
                                                  ? 'gray'
                                                  : '',
                                                cursor:
                                                  isSelectButtonDisabled ||
                                                  (hasReachedSlotLimit &&
                                                    !selectedSlots[slot.id]) ||
                                                  (diagnosticSlotSelected &&
                                                    slotDuration <= 1.0 &&
                                                    !selectedSlots[slot.id])
                                                    ? 'not-allowed'
                                                    : 'pointer',
                                              }}
                                            >
                                              {selectedSlots[slot.id]
                                                ? 'Selected'
                                                : 'Select'}
                                            </Button>

                                            {/* Optional: Display an "X" button if the slot is selected */}
                                            {selectedSlots[slot.id] && (
                                              <IconButton
                                                onClick={() => {
                                                  deselectSlot(slot.id); // Deselect the slot
                                                }}
                                                size="small"
                                                style={{
                                                  color: 'red',
                                                  position: 'absolute',
                                                  right: 5,
                                                  top: 25,
                                                }}
                                              >
                                                <CloseIcon />
                                              </IconButton>
                                            )}

                                            {/**
                                             * The below icons gets shown up for each of the slot
                                             * Meaning, this icon will show up in each of the
                                             * slot
                                             */}
                                            {/* Add PanToolIcon and NoteAltOutlinedIcon */}
                                            <div
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginTop: '10px',
                                              }}
                                            >
                                              {/* PanToolIcon for 'Hold' type */}
                                              {slot.type === 'Hold' && (
                                                <IconButton
                                                  onClick={(event) =>
                                                    handleHoldClick(
                                                      event,
                                                      slot.id
                                                    )
                                                  }
                                                  style={{ marginRight: '8px' }}
                                                  aria-label="Hold Details"
                                                >
                                                  {slot.holdExpirationDateTime ? (
                                                    <PanToolWithTimer
                                                      daysUntilExpiration={calculateDaysUntilExpiration(
                                                        slot.holdExpirationDateTime
                                                      )}
                                                      style={{
                                                        width: '30px',
                                                        height: '30px',
                                                      }}
                                                      sx={{ color: 'red' }}
                                                      size={20}
                                                    />
                                                  ) : (
                                                    <PanToolIcon
                                                      style={{
                                                        width: '30px',
                                                        height: '30px',
                                                      }}
                                                      sx={{ color: 'red' }}
                                                      size={20}
                                                    />
                                                  )}
                                                </IconButton>
                                              )}

                                              {/* NoteAltOutlinedIcon for 'Open' type with note */}
                                              {slot.type === 'Open' &&
                                                slot.note && (
                                                  <IconButton
                                                    onClick={(event) =>
                                                      handleNoteClick(
                                                        event,
                                                        slot.id
                                                      )
                                                    }
                                                    aria-label="Note Details"
                                                  >
                                                    <NoteAltOutlinedIcon
                                                      style={{
                                                        width: '20px',
                                                        height: '20px',
                                                      }}
                                                      sx={{ color: 'blue' }}
                                                      size={20}
                                                    />
                                                  </IconButton>
                                                )}
                                            </div>

                                            {/* Popover for Note Icon */}
                                            <Popover
                                              id={noteId}
                                              open={Boolean(
                                                popoverNoteAnchorEl[slot.id]
                                              )}
                                              anchorEl={
                                                popoverNoteAnchorEl[slot.id]
                                              }
                                              onClose={() =>
                                                handleNotePopoverClose(slot.id)
                                              }
                                              anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                              }}
                                              transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                              }}
                                              TransitionComponent={Fade}
                                              transitionDuration={350}
                                            >
                                              <Paper>
                                                <Typography sx={{ p: 4 }}>
                                                  {slot.note}
                                                  {/* Additional slot history if needed */}
                                                  {slot.holdExpirationDateTime &&
                                                    slot.type === 'Open' && (
                                                      <p
                                                        style={{
                                                          marginTop: '10px',
                                                          fontWeight: 'bold',
                                                        }}
                                                      >
                                                        Slot was previously a
                                                        hold that expired on{' '}
                                                        {moment
                                                          .utc(
                                                            slot.holdExpirationDateTime
                                                          )
                                                          .format(
                                                            'MM/DD/YYYY HH:mm'
                                                          )}
                                                      </p>
                                                    )}
                                                </Typography>
                                              </Paper>
                                            </Popover>

                                            {/* Popover for PanToolIcon */}
                                            <Popover
                                              id={holdId}
                                              open={Boolean(
                                                popoverHoldAnchorEl[slot.id]
                                              )}
                                              anchorEl={
                                                popoverHoldAnchorEl[slot.id]
                                              }
                                              onClose={() =>
                                                handleHoldPopoverClose(slot.id)
                                              }
                                              anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                              }}
                                              transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                              }}
                                              TransitionComponent={Fade}
                                              transitionDuration={350}
                                            >
                                              <Paper>
                                                <Typography sx={{ p: 4 }}>
                                                  <p>
                                                    {`Hold Created By ${getFirstCharacters(
                                                      slot.person_creating_hold
                                                    )} on ${moment(
                                                      slot.holdCreationDate
                                                    ).format('MM/DD/YY')} RE -${
                                                      slot.clientInitials
                                                    }`}
                                                  </p>
                                                  {slot.holdExpirationDateTime && (
                                                    <p
                                                      style={{
                                                        textAlign: 'center',
                                                        color: 'red',
                                                      }}
                                                    >
                                                      {`Hold Expires on ${moment(
                                                        formatSlotDateTime(
                                                          slot.holdExpirationDateTime
                                                        ),
                                                        'ddd M/DD/YY h:mmA'
                                                      ).format(
                                                        'MM/DD/YY hh:mm A'
                                                      )}`}
                                                    </p>
                                                  )}
                                                  <p
                                                    style={{
                                                      textAlign: 'center',
                                                    }}
                                                  >
                                                    {slot.note}
                                                  </p>
                                                </Typography>
                                              </Paper>
                                            </Popover>
                                          </div>
                                        );
                                      })}
                                  </AccordionDetails>
                                </Accordion>
                              );
                            }
                          );
                        })}
                      </CardContent>
                    </Collapse>
                  </Card>
                </div>
              </Col>
            </div>
          </Row>
        </div>
      );
    } else {
      return false;
    }
  } else {
    // eslint-disable-next-line no-unused-expressions
    return (
      <div>
        {/* need to update this section for multiSlot */}
        <Row className="g-2">
          <Col xs={12}>
            {getProviderYesMaybeText(obj)?.provider?.therapy
              ?.providerPriorityScheduling && (
              <div className={styles.priorityRibbon}>Priority</div>
            )}
            <div style={{ width: '100%' }}>
              <Card
                style={{
                  width: '100%',
                  backgroundColor: '#f5f5f5',
                  minHeight: '125px',
                }}
              >
                <CardHeader
                  style={{ height: '50px' }}
                  avatar={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        margin: -10,
                        marginLeft: -25,
                      }}
                    >
                      {!isManual &&
                        uniqueLocationDetailsArray?.map((locationDetails) => (
                          <Col xs={3} key={locationDetails.avatar.letter}>
                            <Box
                              sx={{
                                bgcolor: locationDetails.avatar.color,
                                fontSize: '12px',
                                width: 26,
                                height: 26,
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                              aria-label={provider.name}
                            >
                              {locationDetails.avatar.letter}
                            </Box>
                          </Col>
                        ))}
                      {renderStateBadges()} {/* This line renders the badges */}
                    </div>
                  }
                  action={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        paddingTop: -10,
                        paddingRight: 1,
                      }}
                    >
                      {!isManual &&
                        uniqueFrequencies?.map((frequency) => {
                          let frequencyDetails = getFrequencyAvatar(frequency);
                          return (
                            <Box
                              key={frequencyDetails?.avatar?.letter}
                              sx={{
                                bgcolor: frequencyDetails?.avatar?.color,
                                fontSize: '11px',
                                width: 26,
                                height: 26,
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                              aria-label={frequency}
                            >
                              {frequencyDetails?.avatar?.letter}
                            </Box>
                          );
                        })}
                    </div>
                  }
                />

                <div style={{ marginTop: '-10px', textAlign: 'center' }}>
                  <ThemeProvider theme={theme}>
                    <Typography variant="body" style={{ fontWeight: 'bold' }}>
                      {title}
                    </Typography>
                  </ThemeProvider>
                </div>

                <div style={{ marginTop: '5px' }}>
                  <CardMedia
                    component="img"
                    height="194"
                    image={image}
                    alt="Provider img"
                  />
                </div>
                <CardActions disableSpacing style={{ marginTop: '-10px' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      marginTop: '5px',
                    }}
                  >
                    <div style={{ alignItems: 'center' }}>
                      {isManual && (
                        <Row
                          style={{
                            color: 'red',
                            height: '28px',
                            cursor: 'pointer',
                            marginTop: '10px',
                            paddingLeft: '10px',
                            marginBottom: '10px',
                            paddingRight: '10px',

                            width: '100%',
                          }}
                          onClick={async (e) => {
                            onWithoutCalenderClickHandler(obj, title);
                          }}
                        >
                          {!(
                            entryTypeFormValues.entryType === 'existing' &&
                            entryTypeFormValues.existingClientOptions ===
                              'Turning 18 years-Old'
                          ) ? (
                            'Schedule appointment without Calendar availability'
                          ) : (
                            <div>
                              <Button>Select</Button>
                            </div>
                          )}
                        </Row>
                      )}

                      {getProviderYesMaybeText &&
                        obj &&
                        getProviderYesMaybeText(obj).out.length > 0 && (
                          <Button
                            variant="contained"
                            onClick={handleClick}
                            style={{
                              height: '20px',
                              width: '100%',
                              margin: '20px auto 0',
                            }}
                          >
                            Service Alert
                          </Button>
                        )}

                      <Popover
                        id={popoverId}
                        open={popoverOpen}
                        anchorEl={popoverAnchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        TransitionComponent={Fade}
                        transitionDuration={350}
                      >
                        <Paper>
                          <Typography sx={{ p: 4 }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  getProviderYesMaybeText &&
                                  obj &&
                                  getProviderYesMaybeText(obj).out,
                              }}
                            ></div>
                          </Typography>
                        </Paper>
                      </Popover>
                    </div>
                  </div>
                </CardActions>

                <CardActions>
                  {!isManual && numberOfUniqueOpens > 0 && (
                    <Badge
                      sx={{
                        '.MuiBadge-badge': {
                          bgcolor: 'green',
                          color: 'white',
                          marginTop: '7px',
                        },
                      }}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      badgeContent={numberOfUniqueOpens}
                    >
                      <IconButton>
                        <EventAvailableIcon
                          style={{ width: '22px', height: '22px' }}
                          set
                          your
                          desired
                          dimensions
                          here
                          sx={{ color: 'green' }}
                        />
                      </IconButton>
                    </Badge>
                  )}

                  <Box
                    sx={{
                      color: 'action.active',
                      display: 'flex',
                      flexDirection: 'column',
                      '& > *': {
                        marginBottom: '2px',
                      },
                      '& .MuiBadge-root': {
                        marginRight: 4,
                        marginTop: '5px', // Adjust the marginTop value to move the Badge further down
                      },
                    }}
                  >
                    {!isManual && numberOfUniqueHolds > 0 && (
                      <Badge
                        badgeContent={numberOfUniqueHolds}
                        color="error"
                        sx={{ '& .MuiBadge-badge': { marginTop: '5px' } }}
                      >
                        <IconButton>
                          <PanToolIcon
                            style={{ width: '30px', height: '30px' }}
                            sx={{ color: 'red' }}
                          />
                        </IconButton>
                      </Badge>
                    )}
                  </Box>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'end',
                    }}
                  >
                    {isTopIssuesIncludeInSevice('issue3') && (
                      <span
                        style={{ fontSize: '2em' }}
                        role="img"
                        aria-label="Emoji here"
                      >
                        🥉
                      </span>
                    )}
                    {isTopIssuesIncludeInSevice('issue2') && (
                      <span
                        style={{ fontSize: '2em' }}
                        role="img"
                        aria-label="Emoji here"
                      >
                        🥈
                      </span>
                    )}
                    {isTopIssuesIncludeInSevice('issue1') && (
                      <span
                        style={{ fontSize: '2em' }}
                        role="img"
                        aria-label="Emoji here"
                      >
                        🥇
                      </span>
                    )}
                  </div>

                  {!isManual && !Object.values(groupedSlots).length === 0 && (
                    <ExpandMore
                      expand={expanded}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  )}
                </CardActions>

                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent style={{ width: '100%' }}>
                    {Object.values(groupedSlots).map(
                      (slotGroup, groupIndex) => {
                        const firstSlot = slotGroup[0];
                        const isFirstSlotGroup = true;

                        // Calculate the slot age in days
                        const createdAt = moment(firstSlot.createdAt);
                        const now = moment();
                        const slotAgeInDays = now.diff(createdAt, 'days');

                        // Decide the display text based on the slot age
                        let slotAgeDisplay;
                        if (slotAgeInDays < 1) {
                          slotAgeDisplay = 'Brand New - < 1 day';
                        } else {
                          slotAgeDisplay = `(${slotAgeInDays} days-old)`;
                        }
                        return (
                          <Accordion
                            key={`group${groupIndex}`}
                            expanded={expanded === `group${groupIndex}`}
                            style={{ width: '100%' }}
                          >
                            <AccordionSummary
                              expandIcon={
                                slotGroup.length > 1 && (
                                  <ExpandMoreIcon
                                    onClick={() =>
                                      handleChange(
                                        `group${groupIndex}`,
                                        expanded
                                      )
                                    }
                                  />
                                )
                              }
                              aria-controls={`group${groupIndex}-content`}
                              id={`group${groupIndex}-header`}
                              style={{
                                width: '100%',
                                minHeight: '120px',
                              }}
                            >
                              {/* Display the first upcoming slot in the regular Accordion Summary */}
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                  }}
                                >
                                  <Typography
                                    sx={{ flexShrink: 0, fontSize: '14px' }}
                                    onClick={() => {
                                      onSlotClickHadler(
                                        {
                                          ...slotGroup[groupIndex],
                                          start_date_time: moment(
                                            formatSlotDateTime(
                                              slotGroup[groupIndex]
                                                .start_date_time
                                            ),
                                            'ddd M/DD/YY h:mmA'
                                          ).format('YYYY-MM-DDTHH:mm'),
                                          end_date_time: moment(
                                            formatSlotDateTime(
                                              slotGroup[groupIndex]
                                                .end_date_time
                                            ),
                                            'ddd M/DD/YY h:mmA'
                                          ).format('YYYY-MM-DDTHH:mm'),
                                        },
                                        obj,
                                        title
                                      );
                                    }}
                                  >
                                    {formatSlotDateTime(
                                      firstSlot.start_date_time
                                    )}{' '}
                                    {/* Adjust the formatting based on your requirement */}
                                  </Typography>

                                  {isFirstSlotGroup && (
                                    <Typography
                                      style={{
                                        fontSize: '10px',
                                        color: 'grey',
                                      }}
                                    >
                                      {slotAgeDisplay}
                                    </Typography>
                                  )}
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {firstSlot?.type === 'Open' &&
                                      firstSlot.note && (
                                        <IconButton
                                          onClick={(event) =>
                                            handleNoteClick(event, firstSlot.id)
                                          }
                                        >
                                          <NoteAltOutlinedIcon
                                            style={{
                                              width: '30px',
                                              height: '30px',
                                              marginTop: '5px',
                                            }}
                                            sx={{ color: 'blue' }}
                                          />
                                        </IconButton>
                                      )}

                                    {/**
                                     * The below is the case for the first Slot
                                     * If there is a hold, It will show on the
                                     * first slot
                                     */}
                                    {firstSlot?.type === 'Hold' && (
                                      <IconButton
                                        onClick={(event) =>
                                          handleHoldClick(event, firstSlot.id)
                                        }
                                      >
                                        <PanToolIcon
                                          style={{
                                            width: '30px',
                                            height: '30px',
                                            marginTop: '-3px',
                                          }}
                                          sx={{ color: 'red' }}
                                        />
                                      </IconButton>
                                    )}

                                    <Popover
                                      id={noteId}
                                      open={Boolean(
                                        popoverNoteAnchorEl[firstSlot.id]
                                      )}
                                      anchorEl={
                                        popoverNoteAnchorEl[firstSlot.id]
                                      }
                                      onClose={() =>
                                        handleNotePopoverClose(firstSlot.id)
                                      }
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                      }}
                                      TransitionComponent={Fade}
                                      transitionDuration={350}
                                    >
                                      <Paper>
                                        <Typography sx={{ p: 4 }}>
                                          {firstSlot.note}
                                        </Typography>
                                      </Paper>
                                    </Popover>

                                    <Popover
                                      id={holdId}
                                      open={Boolean(
                                        popoverHoldAnchorEl[firstSlot.id]
                                      )}
                                      anchorEl={
                                        popoverHoldAnchorEl[firstSlot.id]
                                      }
                                      onClose={() =>
                                        handleHoldPopoverClose(firstSlot.id)
                                      }
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                      }}
                                      TransitionComponent={Fade}
                                      transitionDuration={350}
                                    >
                                      <Paper>
                                        <Typography sx={{ p: 4 }}>
                                          {firstSlot.note}
                                        </Typography>
                                      </Paper>
                                    </Popover>
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div>
                                    {getFrequencyText(firstSlot.frequency) && (
                                      <span style={{ fontWeight: 'bold' }}>
                                        {getFrequencyText(firstSlot.frequency)}
                                      </span>
                                    )}
                                  </div>
                                  <div style={{ marginLeft: '10px' }}>
                                    {getLocationDetails(firstSlot).map(
                                      (locationDetail, index) => (
                                        <Box
                                          key={index}
                                          sx={{
                                            bgcolor:
                                              locationDetail.avatar.color,
                                            fontSize: '8px',
                                            width: 18,
                                            height: 18,
                                            borderRadius: '50%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }}
                                          aria-label={
                                            locationDetail.avatar.letter
                                          }
                                        >
                                          {locationDetail.avatar.letter}
                                        </Box>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height:
                                  expanded === `group${groupIndex}`
                                    ? 'auto'
                                    : '40px',
                                overflow: 'hidden',
                              }}
                            >
                              {/* Display the remaining slots in the Accordion Details */}
                              {slotGroup.slice(1).map((slot, slotIndex) => (
                                <div
                                  key={`slot${slotIndex}`}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    minHeight: '80px',
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      flexShrink: 0,
                                      fontSize: '14px',
                                      height: '30px',
                                    }}
                                    onClick={() => {
                                      onSlotClickHadler(
                                        {
                                          ...slot,
                                          start_date_time: moment(
                                            formatSlotDateTime(
                                              slot.start_date_time
                                            ),
                                            'ddd M/DD/YY h:mmA'
                                          ).format('YYYY-MM-DDTHH:mm'),
                                          end_date_time: moment(
                                            formatSlotDateTime(
                                              slot.end_date_time
                                            ),
                                            'ddd M/DD/YY h:mmA'
                                          ).format('YYYY-MM-DDTHH:mm'),
                                        },
                                        obj,
                                        title
                                      );
                                    }}
                                  >
                                    <span style={{ cursor: 'pointer' }}>
                                      {formatSlotDateTime(slot.start_date_time)}{' '}
                                    </span>{' '}
                                    {/* Adjust the formatting based on your requirement */}
                                  </Typography>
                                </div>
                              ))}
                              {/* Add any additional slot details here */}
                            </AccordionDetails>
                          </Accordion>
                        );
                      }
                    )}
                  </CardContent>
                </Collapse>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
