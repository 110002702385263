import React, { memo, useEffect, useState } from 'react';
import moment from 'moment';
import {
  Modal,
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid2,
  List,
  ListItem,
} from '@mui/material';
import { FileCopy as FileCopyIcon } from '@mui/icons-material';
import { Row } from 'reactstrap';
import { removeNullUndefinedValues } from '../../common/utils/ValidationUtils.js';
import ServiceFeeDisplayComponent from '../UI/ServiceFeeDisplayComponent';

// Updated Mapping object for key to display names
const displayNames = {
  reasonForService: 'Reason for Service',
  docsDueDate: 'Custom Docs Due Date',
  exceptionForDocs: 'Reason for Custom Docs Due Date',
  additionalInsurance: 'EAP Insurance Note',
  pronounNotes: 'Pronoun Note',
  schedulingNotes: 'Unusual Scheduling Note',
  surgeonName: 'Name of Surgeon',
  hospitalComingFrom: 'Name of Hospital',
  medicationsTaken: 'Name(s) of Medications Taken',
  NutritionDate: 'Date of Nutrition Appt',
};

const ApptSummaryModal = ({
  open,
  onClose,
  appointmentDetails,
  services,
  serviceGroup,
  documents,
  feeDetails,
  age,
  step03FormValues,
  step05FormValues,
  step06FormValues,
  updateFeeDetails,
  selectedPrivatePayAmount,
  serviceTypeRef,
  insuranceRef,
  settingRef,
  locationRef,
  licenseRef,
  focusField,
  accordionRef1,
  accordionRef3,
  accordionRef5,
  accordionRef6,
  providerRef,
  selectedProviderLicense,
  toggleCardClickable,
  isCardClickable,
  docsDue,
}) => {
  // State to track whether the text is copied
  const [isCopied, setIsCopied] = useState(false);
  const [data, setData] = useState();
  const notes = []; // To collect notes and associate them with their document

  useEffect(() => {
    if (appointmentDetails.nonFilteredData) {
      const cleanObject = removeNullUndefinedValues(
        appointmentDetails?.nonFilteredData
      );
      let data = '';
      Object.keys(cleanObject).forEach((key) => {
        if (key !== 'preferredSetting' && key !== 'followUpPreferredSetting') {
          let displayKey = displayNames[key] ? displayNames[key] : key;
          let displayValue = cleanObject[key];

          // Format the docsDueDate in MM/DD/YY format
          if (key === 'docsDueDate') {
            displayValue = moment(cleanObject[key]).format('MM/DD/YY');
          }

          if (key in displayNames) {
            data += `<span><b>${displayKey}:</b> ${displayValue}</span> <br>`;
          }
        }
      });

      // Explicitly handle null values for medicationsTaken and NutritionDate
      if (
        appointmentDetails?.nonFilteredData?.medicationsTaken === null &&
        appointmentDetails?.nonFilteredData?.reasonForService?.includes(
          'Bariatric Pysch Screening'
        )
      ) {
        data += `<span><b>Name(s) of Medications Taken:</b> None</span> <br>`;
      }
      if (
        appointmentDetails?.nonFilteredData?.NutritionDate === null &&
        appointmentDetails?.nonFilteredData?.reasonForService?.includes(
          'Bariatric Pysch Screening'
        )
      ) {
        data += `<span><b>Date of Nutrition Appt:</b> Not Scheduled</span> <br>`;
      }

      // Extracting careCoordinator username
      let careCoordinatorUsername =
        appointmentDetails?.careCoordinator?.username;

      // Add a protective if condition to check for careCoordinatorUsername and its words count
      if (
        careCoordinatorUsername &&
        careCoordinatorUsername.split(' ').length >= 2
      ) {
        const initials = careCoordinatorUsername
          .split(' ') // Split the username by spaces
          .map((name) => name.charAt(0)) // Get the first character of each word
          .join(''); // Join the initials together

        data += `<span><b>Initials of Admin Entering Notes and Date - ${initials}:</b> ${moment().format(
          'MM/DD/YY'
        )} <br>`;
      }

      if (
        appointmentDetails?.provider === 'Deija McLean, Ph.D. BCBA' &&
        serviceGroup?.includes('Testing')
      ) {
        const serviceTitles = services.map((service) => service.title); // Extract titles from services

        if (
          serviceTitles.includes(
            'Autism Spectrum Disorder - Under 18 Evaluation'
          )
        ) {
          data += `<span><b>Note:</b> Client was informed about private pay rates of $2750 (under 5) / $3350 (over 5) and that the entire balance is due the morning of the 1st appt.</span> <br>`;
        } else if (
          serviceTitles.includes('ADHD Child Comprehensive Evaluation') ||
          serviceTitles.includes('Child Psycho-Educational Evaluation')
        ) {
          data += `<span><b>Note:</b>  Client was informed about private pay rates of $3000 and that the entire balance is due the morning of the 1st appt.</span> <br>`;
        } else if (
          serviceTitles.includes(
            'ADHD/Autism Child Comprehensive Combined Evaluation'
          )
        ) {
          data += `<span><b>Note:</b> Client was informed about private pay rates of $3800 and that the entire balance is due the morning of the 1st appt.</span> <br>`;
        }
      }
      setData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentDetails.nonFilteredData, services, serviceGroup]); // Make sure services is in the dependency array if it's a prop or state

  const handleCopyToClipboard = () => {
    let rawContent = document
      .querySelector('#memoText')
      .innerHTML.replace(/<br>/g, '\n');
    // Remove HTML tags
    let textToCopy = rawContent.replace(/<\/?[^>]+(>|$)/g, '');

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsCopied(true);

        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((error) => {
        console.error('Failed to copy text: ', error);
      });
  };

  const adobeDocuments = documents.filter((doc) =>
    doc.doc_name.includes('(Adobe)')
  );
  const otherDocuments = documents.filter(
    (doc) => !doc.doc_name.includes('(Adobe)')
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: '1000px', // A specific max width for larger screens
          maxHeight: '80vh',
          overflowY: 'auto', // Ensures content is scrollable
          bgcolor: '#ffffff', // Change to a suitable background color
          boxShadow: 24,
          p: 5,
          borderRadius: '8px', // Add border radius
        }}
      >
        <div style={{ flex: 1 }}>
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Summary of Appointment Details
          </Typography>

          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <strong>Name:</strong>
                  </TableCell>
                  <TableCell align="center">
                    {appointmentDetails?.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Phone#:</strong>
                  </TableCell>
                  <TableCell align="center">
                    {appointmentDetails?.phone}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Email:</strong>
                  </TableCell>
                  <TableCell align="center">
                    {appointmentDetails?.email}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Insurance:</strong>
                  </TableCell>
                  <TableCell align="center">
                    {appointmentDetails?.insurance}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Provider Name:</strong>
                  </TableCell>
                  <TableCell align="center">
                    {appointmentDetails?.provider}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Appointment Type:</strong>
                  </TableCell>
                  <TableCell align="center">
                    {appointmentDetails?.appointmentType}
                  </TableCell>
                </TableRow>

                {/**The below is the Row for Displaying the time in Appt */}
                <TableRow>
                  <TableCell>
                    <strong>Time:</strong>
                  </TableCell>
                  <TableCell align="center">
                    {/**
                     * Checking if the slot was single Slot
                     */}
                    {appointmentDetails?.selectedTime &&
                      (typeof appointmentDetails.selectedTime
                        .start_date_time === 'string' ? (
                        // Handle single object case
                        <>
                          -{' '}
                          {`${moment(
                            appointmentDetails?.selectedTime?.start_date_time
                          ).format('llll')}`}{' '}
                          -{' '}
                          {`${moment(
                            appointmentDetails?.selectedTime?.end_date_time
                          ).format('llll')}`}{' '}
                        </>
                      ) : (
                        // Handle multiple slots case
                        /**
                         * Checking if slot was multiple
                         */
                        Object.keys(appointmentDetails.selectedTime).map(
                          (key, index) => {
                            const slot = appointmentDetails.selectedTime[key];
                            return (
                              <div key={key}>
                                Slot {index + 1} Time: -{' '}
                                {`${moment(slot?.start_date_time).format(
                                  'llll'
                                )}`}{' '}
                                -{' '}
                                {`${moment(slot?.end_date_time).format(
                                  'llll'
                                )}`}
                              </div>
                            );
                          }
                        )
                      ))}
                  </TableCell>
                </TableRow>

                {/* The below is the column for showing the Docs Due Date*/}
                <TableRow>
                  <TableCell>
                    <strong>Docs Due Date:</strong>
                  </TableCell>
                  <TableCell align="center">{docsDue}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Button
            variant="contained"
            onClick={onClose}
            sx={{ marginTop: '16px' }}
          >
            Close
          </Button>

          {/*The below Div is for displaying the fees and the docs */}
          <div style={{ marginTop: '16px', marginBottom: '16px' }}>
            <Grid2 container spacing={2}>
              <Grid2 item xs={adobeDocuments.length > 0 ? 6 : 12}>
                <Typography variant="subtitle1">
                  TA Documents to Client
                </Typography>
                <List>
                  {otherDocuments.map((doc, index) => {
                    const asterisk = doc.notes ? '*' : '';
                    if (doc.notes) notes.push(`${asterisk} ${doc.notes}`);
                    return (
                      <ListItem key={index} sx={{ py: 0.5 }}>
                        {doc.doc_name} {asterisk}
                      </ListItem>
                    );
                  })}
                </List>
              </Grid2>
              {adobeDocuments.length > 0 && (
                <Grid2 size={{ xs: 6 }}>
                  <Typography variant="subtitle1">
                    Adobe Documents to Other
                  </Typography>
                  <List>
                    {adobeDocuments.map((doc, index) => {
                      const asterisk = doc.notes ? '*' : '';
                      if (doc.notes) notes.push(`${asterisk} ${doc.notes}`);
                      return (
                        <ListItem key={index} sx={{ py: 0.5 }}>
                          {doc.doc_name} {asterisk}
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid2>
              )}
            </Grid2>

            {notes.length > 0 && (
              <>
                <Typography
                  variant="subtitle1"
                  sx={{ mt: 2, textAlign: 'center' }}
                >
                  Notes:
                </Typography>
                <List
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {notes.map((note, index) => (
                    <ListItem key={index} sx={{ py: 0.5 }}>
                      {note}
                    </ListItem>
                  ))}
                </List>
              </>
            )}

            <Row style={{ margin: 2 }}>
              {feeDetails && (
                <div>
                  <ServiceFeeDisplayComponent
                    step03FormValues={step03FormValues} // Getting passed from Scheduling
                    step05FormValues={step05FormValues} // Assuming this is an additional field impacting fees
                    step06FormValues={step06FormValues} // Getting passed from Scheduling
                    updateFeeDetails={updateFeeDetails} // Getting passed from Scheduling
                    age={age}
                    selectedPrivatePayAmount={selectedPrivatePayAmount} // Getting passed from Scheduling
                    serviceTypeRef={serviceTypeRef} // Getting passed from Scheduling
                    insuranceRef={insuranceRef} // Getting passed from Scheduling
                    settingRef={settingRef} // Getting passed from Scheduling
                    locationRef={locationRef} // Getting passed from Scheduling
                    licenseRef={licenseRef} // Getting passed from Scheduling
                    focusField={focusField} // Getting passed from Scheduling
                    accordionRef1={accordionRef1} // Getting passed from Scheduling
                    accordionRef3={accordionRef3} // Getting passed from Scheduling
                    accordionRef5={accordionRef5} // Getting passed from Scheduling
                    accordionRef6={accordionRef6} // Getting passed from Scheduling
                    providerRef={providerRef} // Getting passed from Scheduling
                    selectedProviderLicense={selectedProviderLicense} // Pass the selected license
                    toggleCardClickable={toggleCardClickable} // Pass the toggle function
                    isCardClickable={isCardClickable} // Pass the clickable state
                  />
                </div>
              )}
            </Row>
          </div>
        </div>

        {/* Right Column */}
        <div style={{ flex: 1, marginLeft: '16px' }}>
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            TA Alert / Memo Section To Copy/Paste
          </Typography>
          <p id="memoText" dangerouslySetInnerHTML={{ __html: data }}></p>

          {/* Copy to Clipboard Button */}
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCopyToClipboard}
            startIcon={<FileCopyIcon />}
            sx={{ marginTop: '16px', display: 'block', margin: 'auto' }}
          >
            {isCopied ? 'Copied!' : 'Copy to Clipboard'}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
export default memo(ApptSummaryModal);
